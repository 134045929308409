import { useState, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './SinglePlayersSearch.scss'
import { setNoSortedParticipants, setParticipants, setUserChangedList } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import Search from '../../reusableComponents/Search/Search'
import Loader from '../../reusableComponents/Loader/Loader'
import { processParticipants } from '../../utils/functions2'

import { selectCommunityPlayers } from '../../redux/selectors'
import { NOSORT, STRENGTH } from '../../utils/constants'
import { setCommunityPlayers } from '../../redux/playersService/playersSlice'
import SortPlayersModal from '../../reusableComponents/SortPlayersModal/SortPlayersModal'
import { ReactComponent as SortIcon } from '../../icons/players_sort.svg'

const SinglePlayersSearch = ({
	participants,
	search,
	searchPlayersList,
	playersLoading,
	limitIsReached
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { initPlayers } = useSelector(selectCommunityPlayers)

	const [singlePlayerSearchValue, setSingleInputValue] = useState('')
	const [openSort, setOpenSort] = useState(false)
	const [sortMethod, setSortMethod] = useState('')

	const handleSinglePlayerInput = useCallback((value) => {
		setSingleInputValue(value ? value : '')
		search(value ? value : '', sortMethod)
	}, [search, sortMethod])

	const handlePlayerInPopupList = useCallback((el) => {
		let updatedParticipants = [...participants]

		if (participants?.some(p => p?.uid === el?.uid)) {
			updatedParticipants = participants?.filter(p => p?.uid !== el?.uid)
		} else {
			if (limitIsReached) {
				return
			} else {
				updatedParticipants = processParticipants([...participants, el])
			}
		}

		dispatch(setParticipants({ participants: updatedParticipants }))
		dispatch(setNoSortedParticipants(updatedParticipants)) // если после этого будет сортировка, то откатится к этому списку
		dispatch(setUserChangedList(true))
	}, [dispatch, participants, limitIsReached])

	function sortPlayers({ sortMethod : method }) {
		let sorted = [...searchPlayersList]

		if (method === NOSORT) {
			dispatch(setCommunityPlayers(initPlayers))
		} else if (method === STRENGTH) {
			sorted = sorted.sort((a, b) => {
				return Number(b.strength ?? 0) - Number(a.strength ?? 0)
			})

			dispatch(setCommunityPlayers(sorted))
		} else {
			sorted = sorted.sort((a, b) => {
				const order = { 'male': 1, 'female': 2, null: 3 }
				return order[a.gender] - order[b.gender]
			})

			dispatch(setCommunityPlayers(sorted))
		}

		setOpenSort(false)
		setSortMethod(method)
		dispatch(setUserChangedList(true))
	}

	if ((playersLoading === 'pending') || (playersLoading === 'uninitialized')) {
		return <Loader />
	}

	return (
		<div className="single-players-search">
			<div className="single-players-search__input-wrapper">
				<Search
					placeholder={t('Search player')}
					value={singlePlayerSearchValue}
					onChange={(e) => handleSinglePlayerInput(e.target.value)}
				/>

				<button
					onClick={() => setOpenSort(true)}
					className="single-players-search__sort"
				>
					<SortIcon />
				</button>
			</div>

			{
				searchPlayersList?.length === 0 &&
				<span className="single-players-search__line"></span>
			}

			{
				searchPlayersList?.map((el, index) =>
					<div
						key={index}
						className="single-players-search__list-item"
						style={{
							backgroundColor: participants?.find(p => p.uid === el.uid) ?
								'var(--palette-live-blue-3)'
								:
								limitIsReached ?
									'var(--palette-live-grey-8)'
									:
									''
						}}
						onClick={() => handlePlayerInPopupList(el, index)}
					>
						<p className="single-players-search__player-name">
							{el.last_name} {el.first_name}
						</p>

						<p className="single-players-search__player-strength">
							{el?.strength || ''}
						</p>

						{/* {
							participants?.find(p => p.uid === el.uid) &&
							<CheckMark />
						} */}
					</div>
				)
			}

			{openSort &&
				<SortPlayersModal
					sortMethod={sortMethod}
					participants={searchPlayersList}
					submit={sortPlayers}
					gender
				/>
			}
		</div>
	)
}

export default memo(SinglePlayersSearch)