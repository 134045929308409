import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'

import { checkTokenExpiration, getLocalStorageItem, resetAuthorization } from '../utils/functions'
import {
	ACCESS,
	ACCESS_EXPIRES_AT,
	AUTH_ERROR_STATUS,
	CONNECTION_ERROR,
	REFRESH,
	REFRESH_EXPIRES_AT,
	requestsWithoutAuth,
	SERVER_ERROR_STATUS
} from '../utils/constants'
import { isCreateComunityRequest, isExeptedError } from '../utils/functions2'

import { setError } from './errorService/errorSlice'
import { getNewAccess, refreshToken } from './authService/refreshApiSlice'
import { sendLogInTg } from './logService/telegramApi'
import {
	setAccessToken,
	setAccessTokenTimeExpiration,
	setAuthorized,
	setRefreshToken,
	setRefreshTokenTimeExpiration
} from './authService/authSlice'

async function basicRequest(args, api, extraOptions, access, guestCode) {
	const baseQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {

			if (guestCode) {
				headers.set('x-auth-code', guestCode)
			} else if (access) {
				headers.set('authorization', `Bearer ${access}`)
			}

			return headers
		}
	})

	const response = await baseQuery(args, api, extraOptions)
	return response
}

const baseQueryWithReauth = async (args, api, extraOptions) => {
	const { dispatch, getState } = api
	const state = getState()
	const { guestCode } = state.auth
	let {
		authorized,
		refresh,
		access,
		accessExpiresAt,
		refreshTokenExpiresAt
	} = state.auth
	const { communityUid } = state.community

	const redux = {
		authorized,
		refresh: JSON.stringify(refresh),
		access: JSON.stringify(access),
		accessExpiresAt,
		refreshTokenExpiresAt,
		guestCode
	}

	const reduxCondition = _.cloneDeep(redux)

	const storageAccess = getLocalStorageItem(ACCESS)
	const storageRefresh = getLocalStorageItem(REFRESH)
	const storageAccessExpiresAt = getLocalStorageItem(ACCESS_EXPIRES_AT)
	const storageRefreshExpiresAt = getLocalStorageItem(REFRESH_EXPIRES_AT)
	const storageAuthorized = getLocalStorageItem('authorized')
	const storageGuestCode = getLocalStorageItem('guestCode')

	const storage = {
		storageAccess: JSON.stringify(storageAccess),
		storageAccessExpiresAt,
		storageRefresh: JSON.stringify(storageRefresh),
		storageRefreshExpiresAt,
		storageAuthorized,
		storageGuestCode
	}

	const storageCondition = _.cloneDeep(storage)

	if ((!authorized || !access || !refresh) && !requestsWithoutAuth.includes(args.url) && !isCreateComunityRequest(args)) {
		if (storageAccess && storageRefresh && storageAccessExpiresAt && storageRefreshExpiresAt) {
			dispatch(setAccessToken(storageAccess))
			dispatch(setRefreshToken(storageRefresh))
			dispatch(setAuthorized(true))
			dispatch(setAccessTokenTimeExpiration(storageAccessExpiresAt))
			dispatch(setRefreshTokenTimeExpiration(storageRefreshExpiresAt))

			refresh = storageRefresh
			access = storageAccess
			accessExpiresAt = storageAccessExpiresAt
			refreshTokenExpiresAt = storageRefreshExpiresAt
			authorized = true

			await sendLogInTg(api, extraOptions, 'Авторизация была утеряна и восстановлена из storage')
		} else {
			const storageData = {
				message: 'В редаксе и в storage нет данных об авторизации',
				storageCondition,
				reduxCondition
			}

			const resultString = '```json\n' + JSON.stringify(storageData, null, 3) + '\n```'

			await sendLogInTg(api, extraOptions, resultString)
			resetAuthorization(dispatch, communityUid, 'no_token/s')
			return { error: AUTH_ERROR_STATUS }
		}
	}

	const usedCondition = {
		refresh: JSON.stringify(refresh),
		access: JSON.stringify(access)
	}
	const currentTime = new Date().getTime()

	if (authorized && !checkTokenExpiration(accessExpiresAt)) {
		const response = await refreshToken(
			refresh, api, extraOptions, dispatch, refreshTokenExpiresAt, communityUid
		)

		if (!response.success) {
			const storageData = {
				message: 'Refresh token почти истек, сброс авторизации',
				storageCondition,
				reduxCondition,
				currentTime: currentTime
			}

			const resultString = '```json\n' + JSON.stringify(storageData, null, 3) + '\n```'

			resetAuthorization(dispatch, communityUid, 'refresh_token_time_expired')
			await sendLogInTg(api, extraOptions, resultString)
			return { error: AUTH_ERROR_STATUS }
		} else {
			access = response.access
		}
	}

	try {
		let result = await basicRequest(args, api, extraOptions, access, guestCode)

		if (result && result?.error) {
			const error = result?.error || {}
			const { status, originalStatus, data = {} } = error

			console.log('result error', result)

			const isException = isExeptedError(data)

			const authCondition = {
				bearer: result?.meta?.request?.headers?.get('Authorization'),
				xAuthCode: result?.meta?.request?.headers?.get('X-Auth-Code'),
				usedCondition,
				storageCondition,
				reduxCondition,
				currentTime: currentTime
			}

			const apiError = {
				url: result?.meta?.request?.url,
				method: result?.meta?.request?.method,
				body: JSON.stringify(args?.body, null, 4),
				error: result?.error,
			}

			if (
				(status !== AUTH_ERROR_STATUS || originalStatus === SERVER_ERROR_STATUS|| status === CONNECTION_ERROR)
				&& !isException
			) {
				const resultString = '```json\n' + JSON.stringify(apiError, null, 3) + '\n```'

				dispatch(setError(error))
				await sendLogInTg(api, extraOptions, resultString)
			} else if (status === AUTH_ERROR_STATUS && authorized) {
				const storageData = {
					message: 'Сервер не принял token. Обновляем access',
					...authCondition,
					apiError
				}

				const resultString = '```json\n' + JSON.stringify(storageData, null, 3) + '\n```'

				await sendLogInTg(api, extraOptions, resultString)

				const accessResult = await getNewAccess(api, extraOptions, refresh)

				if (accessResult?.data?.access) {
					result = await basicRequest(args, api, extraOptions, accessResult?.data?.access, guestCode)

					dispatch(setAccessToken(accessResult?.data?.access))

					await	sendLogInTg(api, extraOptions, 'Сервер принял access')
				} else {
					const storageData = {
						message: 'Не удалось сделать дополнительное обновление токена. Сброс авторизации',
						...authCondition,
						apiError: accessResult?.error
					}

					const resultString = '```json\n' + JSON.stringify(storageData, null, 3) + '\n```'

					await sendLogInTg(api, extraOptions, resultString)
					resetAuthorization(dispatch, communityUid, 'refresh_token_invalid')
					return
				}
			}
		}

		console.log(result)

		return result
	} catch (e) {
		const resultString = '```json\n' + JSON.stringify(e, null, 3) + '\n```'

		await sendLogInTg(api, extraOptions, resultString)
		console.log('basic api catch error', e)
	}
}

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({})
})