import { useState, useEffect, useCallback, memo, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './TournamentParticipants.scss'
import {
	selectAuth,
	selectCommunity,
	selectCommunityPlayers,
	selectParticipants,
	selectTournament,
	selectTournamentOptions
} from '../../redux/selectors'
import { findPlayers, getParameters } from '../../utils/functions'
import { TYPE_SINGLES, TYPE_DOUBLES, FULFILLED, STRENGTH } from '../../utils/constants'
import {
	useAddSinglePlayersMutation,
	useRemoveParticipantMutation,
	useAddDoublesMutation,
	useRemoveDoubleMutation,
	useSeedParticipantsMutation,
	useSeedDoublesMutation,
} from '../../redux/tournamentParticipantsService/tournamentParticipantsApiSlice'
import {
	setOptionsOfDouble,
	setOptionsOfSingle,
	setParticipants,
	setCreateNewPlayer,
	setAddByList,
	setCreatedPlayers,
	setParsedDoubles,
	setDndBetweenIsOn,
	setUserChangedList,
	setNoSortedParticipants,
	setParsedDoublesInitial,
} from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { useGetPlayersQuery } from '../../redux/playersService/playersApiSlice'
import {
	setCurrentStageNumber,
	setImmutableProcessedLevels,
	setInitialStage,
	setProcessedLevelsForBuildMode,
	setProcessedStage
} from '../../redux/stageService/stageSlice'
import { setTournamentData } from '../../redux/singleTournamentService/singleTournamentSlice'
import Popup from '../../reusableComponents/Popup/Popup'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import DoubleSearchForPlayers from '../../extendedComponents/DoubleSearchForPlayers/DoubleSearchForPlayers'
import SinglePlayersSearch from '../../extendedComponents/SinglePlayersSearch/SinglePlayersSearch'
import TournamentMenu from '../../reusableComponents/TournamentMenu/TournamentMenu'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import TournamentParticipantsList from '../../extendedComponents/TournamentParticipantsList/TournamentParticipantsList'
import AdditionPlayersByList from '../../extendedComponents/AdditionPlayersByList/AdditionPlayersByList'
import { useFillAutoStageMutation, useGetStageQuery, useRemoveStageMutation } from '../../redux/stageService/stageApiSlice'
import Loader from '../../reusableComponents/Loader/Loader'

import { getChanges, listIsChanged, saveIsAllowed, updateDoublesUids } from './externalFunctions'

const phraseBeforeToRemove = 'В текущем режиме изменение списка участников влечет за собой удаление всех этапов. Разрешить изменения?'
const phraseBeforeToRefill= 'В текущем режиме изменение посева участников влечет за собой перезаполнение первого этапа. Разрешить изменения?'

function TournamentParticipants({ tournamentRefetch, categories, tournamentStartDate }) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { tournamentParams } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { authorized, guestCode } = useSelector(selectAuth)
	const { communityUid } = useSelector(selectCommunity)
	const tournament = useSelector(selectTournament)
	const {
		players,
		doubles,
		type,
		stages_count,
		uid
	} = tournament
	const { participants: options, settings = {} } = useSelector(selectTournamentOptions)
	const {
		participants,
		optionsOfSingle,
		optionsOfDouble,
		name,
		addByList,
		createNewPlayer
	} = useSelector(selectParticipants)
	const { players: communityPlayers } = useSelector(selectCommunityPlayers)
	const { initPlayers } = useSelector(selectCommunityPlayers)

	const [addSinglePlayers] = useAddSinglePlayersMutation()
	const [removeParticipant] = useRemoveParticipantMutation()
	const [addDouble] = useAddDoublesMutation()
	const [removeDouble] = useRemoveDoubleMutation()
	const [removeStage] = useRemoveStageMutation()
	const [fillAutoStage] = useFillAutoStageMutation()
	const [seedSinglePlayers] = useSeedParticipantsMutation()
	const [seedDoubles] = useSeedDoublesMutation()

	const [searchPlayersList, setSearchPlayersList] = useState([])
	const [showPlayers, setShowPlayers] = useState(false)
	const [warningOpened, setWarningOpened] = useState(false)
	const [requestIsActive, setRequestIsActive] = useState({ save: false, refillStage: false, removeStage: false })
	const [openedOptions, setOpenedOptions] = useState(false)
	const [needToRefill, setNeedToRefilled] = useState(false)
	const [showSaveButton, setShowSaveButton] = useState(false)

	const limitReached = participants?.length > settings?.maxParticipants - 1

	const { status } = useGetPlayersQuery({ community_uid: communityUid },
		{ skip: !authorized || guestCode, refetchOnMountOrArgChange: true })

	const { data: stageData = {}, status: statusStage } = useGetStageQuery({
		tournament_uid: tournamentUid,
		stageNumber: 1
	}, { skip: !stages_count || stages_count < 1 })

	useEffect(() => {
		if ((doubles || players) && (participants?.length < 1 || !participants)) {
			return
		}

		const changed = listIsChanged(doubles || players || [], participants || [])
		setShowSaveButton(changed)
	}, [doubles, participants, players])

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.onbeforeunload = function() {
				dispatch(setUserChangedList(false))
				dispatch(setCreateNewPlayer(false))
				dispatch(setCreatedPlayers([]))
				dispatch(setParticipants([]))
				dispatch(setNoSortedParticipants([]))
				dispatch(setParsedDoublesInitial([]))
				dispatch(setParsedDoubles([]))
			}
		}
		dispatch(setDndBetweenIsOn(false))
	}, [])

	useEffect(() => {
		if (communityPlayers.length > 0) {
			setSearchPlayersList(communityPlayers)
		}
	}, [communityPlayers])

	const limitIsReached = useMemo(() => {
		const preLimit = settings?.maxParticipants - 1

		if (participants?.length > preLimit) {
			return true
		} else {
			return false
		}
	}, [participants, settings?.maxParticipants])

	const permissions = useMemo(() => {
		const permissions = {
			matchStarted: false,
			stageExists: false,
			add: true,
			remove: true,
			seed: true
		}

		if (stages_count < 1) return permissions

		permissions.stageExists = true

		if (options?.limited && stageData?.levels) {
			const playedSetExists = stageData?.levels?.some(lvl => {
				return lvl.groups.some(grp => {
					return grp.matches.some(match => match.sets.length > 0)
				})
			})

			const { matchNotStarted, matchStarted } = options

			if (!playedSetExists) {
				permissions.add = matchNotStarted.addParticipants
				permissions.remove = matchNotStarted.removeParticipants
				permissions.seed = matchNotStarted.seedParticipants
			} else {
				permissions.matchStarted = true
				permissions.add = matchStarted.addParticipants
				permissions.remove = matchStarted.removeParticipants
				permissions.seed = matchStarted.seedParticipants
			}
		}

		return permissions
	}, [stageData, options, stages_count])

	// модальное окно

	const showPopup = useCallback(() => {
		if (limitReached) {
			return
		}

		setShowPlayers(true)

		document.body.style.overflow = 'hidden'
	}, [limitReached])

	function closePopup() {
		setShowPlayers(false)

		document.body.style.overflow = 'auto'
	}

	function handlePopupCondition() {
		dispatch(setCreatedPlayers([]))
		dispatch(setCreateNewPlayer(false))
		search('', '')
		closePopup(false)

		// добаввление списком пока заблокировано

		// else if (addByList) {
		// 	showPopup()
		// 	dispatch(setAddedPlayersByList([NEW_PLAYER_BY_LIST]))
		// 	dispatch(setAddByList(false))
		// }
	}

	const search = useCallback((value, sortMethod) => {
		let foundPlayers = [...initPlayers]

		if (value !== '') {
			foundPlayers = findPlayers(value, initPlayers)
		}

		if (sortMethod) {
			// если для поиска используется сортировка, то сортируем первичный отфильтрованный список
			foundPlayers = sortPlayers(foundPlayers, sortMethod)
		}

		setSearchPlayersList(foundPlayers)
	}, [initPlayers])

	function sortPlayers(list, sortMethod) {
		let sorted = [...list]

		if (sortMethod === STRENGTH) {
			sorted = sorted.sort((a, b) => {
				return Number(b.strength ?? 0) - Number(a.strength ?? 0)
			})
		} else {
			sorted = sorted.sort((a, b) => {
				const order = { 'male': 1, 'female': 2, null: 3 }
				return order[a.gender] - order[b.gender]
			})
		}

		return sorted
	}

	function removeParticipantFromList() {
		const participant = type === TYPE_SINGLES ? optionsOfSingle : optionsOfDouble
		const updatedList = participants?.filter(p => participant.uid !== p?.uid)

		dispatch(setParticipants({ participants: updatedList }))
		dispatch(setNoSortedParticipants(updatedList))  // если после этого будет сортировка, то откатится к этому списку
		dispatch(setOptionsOfDouble({}))
		dispatch(setOptionsOfSingle({}))
		dispatch(setUserChangedList(true))
		setOpenedOptions(false)
	}

	// сохранение результата редактирования на сервере

	function defineNextAction() {
		if (stages_count > 0) {
			const { allowed, added, removed } = saveIsAllowed(doubles || players, participants, type, permissions)

			if (!allowed) {
				setNeedToRefilled(
					options.matchNotStarted.refillGroupsAfterSeed
					&& !permissions.matchStarted && added.length < 1 && removed.length < 1
				)

				setWarningOpened(true)
			} else {
				saveParticipants()
			}
		} else {
			saveParticipants()
		}
	}

	async function saveParticipants() {
		if (requestIsActive.save) return

		setRequestIsActive(prev => { return { ...prev, save: true } })

		let state = [...participants]

		const { added, removed, seeded } = getChanges(doubles || players, participants, type)

		if (removed?.length > 0) {
			await excludeParticipant(removed)
		}

		if (added.length > 0) {
			const addedParticipants = await includeParticipant(added)

			if (type === TYPE_DOUBLES) {
				state = await updateDoublesUids(state, addedParticipants)
			}
		}

		if (seeded) {
			const newOrder = state.map(item => item.uid)
			state = await saveSeed(newOrder)
		}

		await tournamentRefetch()

		dispatch(setUserChangedList(false))
		setRequestIsActive(prev => { return { ...prev, save: false } })
		setShowSaveButton(false)
	}

	async function includeParticipant(newPlayers) {
		try {
			let body = null
			let response = {}

			if (type === TYPE_SINGLES) {
				body = {
					'player_uids': newPlayers
				}

				response = await addSinglePlayers({ tournament_uid: tournamentUid, body: body }).unwrap()

			} else if (type === TYPE_DOUBLES) {
				body = {
					'doubles': newPlayers
				}

				response = await addDouble({ tournament_uid: tournamentUid, body: body }).unwrap()
			}

			if (response?.error) {
				setRequestIsActive(prev => { return { ...prev, save: false } })
				console.log('Include participant return error', response?.error)
				return
			}

			return response
		} catch (e) {
			dispatch(setParticipants({ participants: players || doubles || [] }))
			dispatch(setNoSortedParticipants(players || doubles || [])) // начальный неотсортированный список
			setRequestIsActive(prev => { return { ...prev, save: false } })
			setShowSaveButton(false)
			console.log('Include participant catch error', e)
		}
	}

	async function excludeParticipant(removedPlayers) {
		try {
			let body = null
			let response = {}

			if (type === TYPE_SINGLES) {
				body = {
					'player_uids': removedPlayers
				}

				response = await removeParticipant({
					tournament_uid: tournamentUid,
					body: body
				}).unwrap()
			} else if (type === TYPE_DOUBLES) {
				body = {
					'double_uids': removedPlayers
				}

				response = await removeDouble({
					tournament_uid: tournamentUid,
					body: body
				}).unwrap()
			}

			if (response?.error) {
				dispatch(setParticipants({ participants: players || doubles || [] }))
				dispatch(setNoSortedParticipants(players || doubles || [])) // начальный неотсортированный список
				setRequestIsActive(prev => { return { ...prev, save: false } })
				setShowSaveButton(false)
				console.log('Exclude participant return error', response?.error)
				return
			}

			return response
		} catch (e) {
			dispatch(setParticipants({ participants: players || doubles || [] }))
			dispatch(setNoSortedParticipants(players || doubles || [])) // начальный неотсортированный список
			setRequestIsActive(prev => { return { ...prev, save: false } })
			setShowSaveButton(false)
			setRequestIsActive(prev => { return { ...prev, save: false } })
			console.log('Exclude participant catch error', e)
		}
	}

	async function saveSeed(seed) {
		try {
			let body = null
			let response = {}

			if (type === TYPE_SINGLES) {
				body = {
					'player_uids': seed
				}

				response = await seedSinglePlayers({ tournament_uid: tournamentUid, body: body }).unwrap()

			} else if (type === TYPE_DOUBLES) {
				body = {
					'double_uids': seed
				}

				response = await seedDoubles({ tournament_uid: tournamentUid, body: body }).unwrap()
			}

			if (response?.error) {
				console.log('Seed participant return error', response?.error)
				setRequestIsActive(prev => { return { ...prev, save: false } })
				return
			}

			return response
		} catch (e) {
			setRequestIsActive(prev => { return { ...prev, save: false } })
			console.log('Seed participant catch error', e)
		}
	}

	// работа предупреждающиего уведомления

	function warningAgree() {
		saveParticipants()

		if (needToRefill) {
			refillStage()
		} else {
			removeStages()
		}

		setWarningOpened(false)
	}

	function cancelChanges() {
		dispatch(setParticipants({ participants: players || doubles || [] }))
		dispatch(setNoSortedParticipants(players || doubles || [])) // если после этого будет сортировка, то откатится к этому списку
		setWarningOpened(false)
		dispatch(setUserChangedList(false))
	}

	async function removeStages() {
		setRequestIsActive(prev => { return { ...prev, removeStages: true } })

		try {
			let response = {}

			for (let i = 0; i < stages_count; i++) {
				response = await removeStage({
					tournament_uid: tournamentUid
				}).unwrap()

				if (response?.error) {
					console.log(`Remove stage return error: ${response?.error}`)
				}
			}

			const updatedTournament = {
				...tournament,
				stages_count: response.stages_count
			}

			await tournamentRefetch()

			dispatch(setTournamentData(updatedTournament))
			dispatch(setProcessedStage({}))
			dispatch(setCurrentStageNumber(1))
			dispatch(setInitialStage({}))
			dispatch(setProcessedLevelsForBuildMode([]))
			dispatch(setImmutableProcessedLevels([]))

			setRequestIsActive(prev => { return { ...prev, removeStages: false } })
		} catch (error) {
			console.log(`HandleRemoveStage stage catch error ${error}`)
			setRequestIsActive(prev => { return { ...prev, stageRemove: false } })
		}
	}

	async function refillStage() {
		setRequestIsActive(prev => { return { ...prev, refillStage: true } })

		try {
			await fillAutoStage({ tournament_uid: tournamentUid, stageNumber: 1 }).unwrap()
			setRequestIsActive(prev => { return { ...prev, refillStage: false } })
		}
		catch (e) {
			setRequestIsActive(prev => { return { ...prev, refillStage: false } })
			console.log('refillStageSubmit catch error', e)
		}
	}

	// работа с опциями турнира

	const openOptions = useCallback((el) => {
		if (!authorized || guestCode) return

		if (type === TYPE_SINGLES) {
			dispatch(setOptionsOfSingle({
				uid: el.uid,
				value: el.display_name || ''
			}))
		} else {
			dispatch(setOptionsOfDouble({
				uid: el.uid,
				value1: el.player1?.display_name || '',
				value2: el.player2?.display_name || '',
				player1: el.player1,
				player2: el.player2
			}))
		}

		setOpenedOptions(true)
	}, [authorized, dispatch, type, guestCode])

	// function onChangeSingleNickname(value) {
	// 	dispatch(setOptionsOfSingle({
	// 		...optionsOfSingle,
	// 		value: value
	// 	}))
	// }

	// function onChangeFirstNickname(value) {
	// 	dispatch(setOptionsOfDouble({
	// 		...optionsOfDouble,
	// 		value1: value
	// 	}))
	// }

	// function onChangeSecondNickname(value) {
	// 	dispatch(setOptionsOfDouble({
	// 		...optionsOfDouble,
	// 		value2: value
	// 	}))
	// }

	// function saveParticipantOptions() {

	// }

	// function closeOptions() {
	// 	setOpenedOptions(false)
	// 	dispatch(setOptionsOfSingle({
	// 		uid: '',
	// 		value: ''
	// 	}))
	// 	setOptionsOfDouble({
	// 		uid: '',
	// 		value1: '',
	// 		value2: '',
	// 		player1: {},
	// 		player2: {}
	// 	})
	// }

	// динамические стили

	function getWrapperStyle() {
		const gapFromMenu = '80px'

		return {
			paddingBottom: showSaveButton ? gapFromMenu : ''
		}
	}

	return (
		<>
			<HeaderTournament
				name={name}
				date={tournamentStartDate}
				categories={categories}
				copyLink={authorized && !guestCode ? true : false}
			/>

			{(statusStage !== FULFILLED && stages_count > 0) || (status !== FULFILLED && authorized && !guestCode) ?
				<Loader />
				:
				<main
					className="tournament-participants"
					style={getWrapperStyle()}
				>
					<div className="tournament-participants__wrapper">
						{
							authorized && !guestCode &&
							<div className="tournament-participants__button-add">
								<RoundedButton
									title={t('Add participant')}
									onClick={showPopup}
									background="grey"
								/>
							</div>
						}

						{
							authorized && !guestCode && permissions?.stageExists  ?
								!permissions.matchStarted && !permissions.add && !permissions.remove && !permissions.seed?
									<p className="tournament-participants__alert">
										Этап создан.
										Изменение состава повлечет за собой удаление этапов.
										Изменение посева приведет к перезаполнению групп первого этапа
									</p>
									: permissions.matchStarted && !permissions.add && !permissions.remove ?
										<p className="tournament-participants__alert">
											Появились результаты игр.
											Изменение состава повлечет за собой удаление этапов
										</p>
										:
										<></>
								:
								''
						}

						<TournamentParticipantsList openOptions={openOptions} />
					</div>

					<div className="tournament-participants__button-save">
						{
							showSaveButton && participants && authorized && !guestCode &&
							<RoundedButton
								onClick={defineNextAction}
								title={t('Save')}
								border
								loading={requestIsActive.save}
								shadow
							/>
						}
					</div>

					{
						(showPlayers || createNewPlayer || addByList) && authorized && !guestCode &&
						<Popup
							title={'Add participant'}
							arrowBack
							onBack={handlePopupCondition}
						>
							{!addByList &&
								<div className="tournament-participant__popup-search-buttons">
									{/* <RoundedArrowBlock
										text={'Добавить списком'} onClick={() => {
											dispatch(setCreateNewPlayer(false))
											dispatch(setAddByList(true))
										}}
									/> */}

									<RoundedButton
										title={'Новый игрок'}
										background="grey"
										onClick={() => {
											dispatch(setCreateNewPlayer(true))
											dispatch(setAddByList(false))
											navigate(`/tournament/${uid}/participants/form`)
										}}
									/>
								</div>
							}
							{
								type === TYPE_SINGLES && !addByList &&
								<SinglePlayersSearch
									search={search}
									searchPlayersList={searchPlayersList}
									participants={participants}
									playersLoading={status}
									limitIsReached={limitIsReached}
								/>
							}
							{
								type === TYPE_DOUBLES && !addByList &&
								<DoubleSearchForPlayers
									search={search}
									searchPlayersList={searchPlayersList}
									participants={participants}
									type={type}
									playersLoading={status}
									limitIsReached={limitIsReached}
								/>
							}

							{addByList &&
								<AdditionPlayersByList communtyPlayers={communityPlayers} />
							}
						</Popup>
					}

					{openedOptions &&
						<Popup title={'Опции участника'} close onClose={() => setOpenedOptions(false)}>
							<div className="tournament-participants__options">
								{/* {settings?.displayName && <p>Табличное имя</p>} */}

								{/* {settings?.displayName ?
									type === TYPE_SINGLES ?
										<Input
											value={optionsOfSingle.value}
											maxLength={8}
											type={'text'}
											placeholder={'Участник'}
											onChange={(e) => onChangeSingleNickname(e.target.value)}
										/>
										:
										<>
											<Input
												value={optionsOfDouble.value1}
												maxLength={8}
												type={'text'}
												placeholder={'Участник 1'}
												onChange={(e) => onChangeFirstNickname(e.target.value)}
											/>

											<Input
												value={optionsOfDouble.value2}
												maxLength={8}
												type={'text'}
												placeholder={'Участник 2'}
												onChange={(e) => onChangeSecondNickname(e.target.value)}
											/>
										</>
									:
									<p className="tournament-participants__options-text">Нет доступных опций</p>
								} */}

								{/* {settings?.displayName &&
									<div className="tournament-participants__warning-buttons">
										<RoundedButton
											title={'Сохранить'}
											background={'white'}
											border={true}
											onClick={saveParticipantOptions}
										/>

										<RoundedButton title={'Отменить'} onClick={closeOptions} />
									</div>
								} */}

								<button onClick={() => removeParticipantFromList()}>Удалить участника</button>
							</div>
						</Popup>
					}

					{warningOpened &&
						<Popup title={'Внимание'}>
							<div className="tournament-participants__warning">
								<p>
									{needToRefill ?
										phraseBeforeToRefill
										:
										phraseBeforeToRemove
									}
								</p>

								<div className="tournament-participants__warning-buttons">
									<RoundedButton
										title={'Да'}
										background={'white'}
										border={true}
										onClick={warningAgree}
										loading={requestIsActive.refillStage || requestIsActive.removeStage || requestIsActive.save}
									/>

									<RoundedButton title={'Нет'} onClick={cancelChanges} border/>
								</div>
							</div>
						</Popup>
					}
				</main>
			}

			<TournamentMenu />
		</>
	)
}

export default memo(TournamentParticipants)