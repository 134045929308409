import { apiSlice } from '../apiSlice'

export const venuesApi = apiSlice.injectEndpoints({
	reducerPath: 'venuesApi',
	tagTypes: ['Venues'],
	endpoints: (build) => ({
		getVenues: build.query({
			query: (community_uid) => `tennis/communities/${community_uid}/venues/`,
			providesTags: ['Venues']
		}),
		getVenue: build.query({
			query: (uid) => `tennis/venues/${uid}/`,
			providesTags: ['Venue']
		}),
		addVenue: build.mutation({
			query: ({ body }) => ({
				url: 'tennis/venues/',
				method: 'POST',
				body
			}),
			invalidatesTags: ['Venues, Community']
		}),
		updateVenue: build.mutation({
			query: ({ body, venue_uid }) => ({
				url: `tennis/venues/${venue_uid}/`,
				method: 'PATCH',
				body
			}),
			invalidatesTags: ['Venue, Community']
		}),
		removeVenue: build.mutation({
			query: ({ venue_uid }) => ({
				url: `tennis/venues/${venue_uid}/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Venues, Community']
		}),
	})
})

export const {
	useGetVenuesQuery,
	useGetVenueQuery,
	useAddVenueMutation,
	useUpdateVenueMutation,
	useRemoveVenueMutation
} = venuesApi