import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import './RoundRobinGridPrint.scss'
import { selectTournament } from '../../redux/selectors'
import { NODE_TYPE_IN} from '../../utils/constants'
import { generateGroupOrLevelsName } from '../../utils/functions'

function RoundRobinGrid({ group }) {
	const { players, doubles } = useSelector(selectTournament)
	const [grid, setGrid] = useState([])

	useEffect(() => {
		if (Object.keys(group).length !== 0) {
			const gridArr = group.nodes.flatMap(node => {
				let playerObject

				if (node.type === NODE_TYPE_IN) {
					if (node.double_uid === null && node.player_uid === null) {
						return node
					} else if (players) {
						playerObject = players.find(player => player.uid === node.player_uid)
					} else if (doubles) {
						playerObject = doubles.find(doublesPlayer => doublesPlayer.uid === node.double_uid)
					}

					if (playerObject) {
						return {
							...playerObject,
							nodeUid: node?.uid
						}
					}
				}

				return null
			}).filter(player => player !== null)

			setGrid(gridArr)
		}
		else {
			return
		}
	}, [doubles, players, group])

	return (
		<table className="round-robin__table">
			<thead>
				<tr>
					<th className="round-robin__header" style={{ width: '150px' }}>
						{ generateGroupOrLevelsName(group.order_number - 1)}
					</th>

					{
						grid?.map((_, index) => {
							return (
								<th className="round-robin__header" key={index}>
									{ index + 1 }
								</th>
							)
						})
					}

					<th className="round-robin__header">M</th>
					<th className="round-robin__header">S</th>
					<th className="round-robin__header">G</th>
					<th className="round-robin__header">R</th>
				</tr>
			</thead>

			<tbody>
				{
					grid?.map((itemVer, indexVertical) => {
						return (
							<tr key={indexVertical}>
								{
									itemVer.player1 ?
										<td>
											<span className="round-robin__dubble-name">
												<span>{ indexVertical + 1 }</span>

												{
													itemVer.player1?.formedName ?
														<div>
															<span>
																{ itemVer.player1?.formedName }
															</span>

															<span>
																{ itemVer.player2?.formedName }
															</span>
														</div>
														:
														<>
															{ itemVer.name }
														</>
												}
											</span>
										</td>
										:
										<td>
											<span className="round-robin__single-name">
												<span>
													{ indexVertical + 1 }
												</span>

												{ itemVer?.formedName ? itemVer.formedName : itemVer?.name }
											</span>
										</td>
								}
							
								{
									grid?.map((itemHor, indexHorizontally) => {
										return (
											<td key={indexHorizontally} className={ indexVertical === indexHorizontally ? 'round-robin__black-block' : '' }></td>
										)
									})
								}

								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						)
					})
				}
			</tbody>
		</table>
	)
}

export default RoundRobinGrid