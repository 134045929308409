import { useSelector } from 'react-redux'

import './RoundRobinShort.scss'
import { selectTournament } from '../../../redux/selectors'
import { generateGroupOrLevelsName } from '../../../utils/functions'
import { TYPE_SINGLES } from '../../../utils/constants'
import StageGroupIcon from '../../../reusableComponents/StageGroupIcon/StageGroupIcon'
import { ReactComponent as Ball } from '../../../icons/tennis_ball.svg'
import Tooltip from '../../../reusableComponents/Tooltip/Tooltip'

function RoundRobinShort({ grid, group, gridResults, getMatch, checkMatch }) {
	const { type } = useSelector(selectTournament)

	const groupName = group?.name ? group.name : generateGroupOrLevelsName(group?.order_number - 1)
	const firstPlayer = grid ? grid[0] : {}
	const secondPlayer = grid ? grid[1] : {}
	const firstResult = gridResults ? gridResults[0]?.ranking : null
	const secondResult = gridResults ? gridResults[1]?.ranking : null

	return (
		<div className="round-robin-short">
			<div className="round-robin-short__column">
				<p className="round-robin-short__title">
					{groupName}

					<StageGroupIcon type={group?.type} styleProp={{
						fill: 'var(--palette-live-grey-0)',
						height: '18.25px'
					}} />
				</p>

				{
					type === TYPE_SINGLES ?
						<div className="round-robin-short__singles">
							<div className="round-robin-short__singles-item">
								<span className="round-robin-short__order">1</span>

								<Tooltip
									content={`${firstPlayer?.last_name} ${firstPlayer?.first_name || ''} ${firstPlayer?.middle_name || ''}`}
									disable={firstPlayer?.formedName ? false : true}
								>
									<p className="round-robin-short__singles-name">
										{firstPlayer?.formedName || firstPlayer?.name || <span className="empty-player"></span>}
									</p>
								</Tooltip>
							</div>

							<div className="round-robin-short__singles-item">
								<span className="round-robin-short__order">2</span>

								<Tooltip
									content={`${secondPlayer?.last_name} ${secondPlayer?.first_name || ''} ${secondPlayer?.middle_name || ''}`}
									disable={secondPlayer?.formedName ? false : true}
								>
									<p className="round-robin-short__singles-name">
										{secondPlayer?.formedName || secondPlayer?.name || <span className="empty-player"></span>}
									</p>
								</Tooltip>
							</div>
						</div>
						:
						<div className="round-robin-short__doubles">
							<div className="round-robin-short__doubles-item">
								<span className="round-robin-short__order">1</span>

								{firstPlayer?.doubleUid ?
									<>
										<Tooltip
											content={`${firstPlayer?.player1?.last_name} ${firstPlayer?.player1?.first_name || ''} ${firstPlayer?.player1?.middle_name || ''}`}
											disable={firstPlayer?.player1?.formedName ? false : true}
										>
											<p className="round-robin-short__doubles-name">
												{firstPlayer?.player1?.formedName}
											</p>
										</Tooltip>

										<Tooltip
											content={`${firstPlayer?.player2?.last_name} ${firstPlayer?.player2?.first_name || ''} ${firstPlayer?.player2?.middle_name || ''}`}
											disable={firstPlayer?.player2?.formedName ? false : true}
										>
											<p className="round-robin-short__doubles-name">
												{firstPlayer?.player2?.formedName}
											</p>
										</Tooltip>
									</>
									:
									<p className="round-robin-short__doubles-name">
										{
											firstPlayer?.name
											|| <span className="empty-player"></span>
										}
									</p>
								}
							</div>

							<div className="round-robin-short__doubles-item">
								<span className="round-robin-short__order">2</span>

								{secondPlayer?.doubleUid ?
									<>
										<Tooltip
											content={`${secondPlayer?.player1?.last_name} ${secondPlayer?.player1?.first_name || ''} ${secondPlayer?.player1?.middle_name || ''}`}
											disable={secondPlayer?.player1?.formedName ? false : true}
										>
											<p className="round-robin-short__doubles-name">
												{secondPlayer?.player1?.formedName}
											</p>
										</Tooltip>

										<Tooltip
											content={`${secondPlayer?.player2?.last_name} ${secondPlayer?.player2?.first_name || ''} ${secondPlayer?.player2?.middle_name || ''}`}
											disable={secondPlayer?.player2?.formedName ? false : true}
										>
											<p className="round-robin-short__doubles-name">
												{secondPlayer?.player2?.formedName}
											</p>
										</Tooltip>
									</>
									:
									<p className="round-robin-short__doubles-name">
										{
											secondPlayer?.name
											|| <span className="empty-player"></span>
										}
									</p>
								}
							</div>
						</div>
				}
			</div>

			<div className="round-robin-short__separator"></div>

			{group?.matches?.length > 0 &&
				<div className="round-robin-short__column">
					<div className="round-robin-short__title"></div>

					<div className="round-robin-short__match">
						{checkMatch(firstPlayer, secondPlayer) && <Ball className="round-robin-short__match-ball" />}

						{getMatch(firstPlayer, secondPlayer, 0, 1)}
					</div>
				</div>
			}

			<div className="round-robin-short__column result-column">
				<p className="round-robin-short__title">R</p>

				<div className="round-robin-short__result">{firstResult}</div>
				<div className="round-robin-short__result">{secondResult}</div>
			</div>
		</div>
	)
}

export default RoundRobinShort