import { useNavigate } from 'react-router-dom'

import './Missing.scss'
import Button from '../../reusableComponents/Button/Button'

function Missing() {
	const navigate = useNavigate()

	return (
		<main className="missing">
			<div className="missing__wrapper">
				<h1 className="missing__title">
					Страница не найдена
				</h1>
			</div>

			<div className="missing__button">
				<Button
					title="На главную"
					onClick={() => navigate('/')}
				/>
			</div>
		</main>
	)
}

export default Missing