import React, { useCallback, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import './TournamentsList.scss'
import TournamentBlock from '../../reusableComponents/TournamentBlock/TournamentBlock'
import TournamentYearBlock from '../../reusableComponents/TournamentYearBlock/TournamentYearBlock'
import { setCurrentStageNumber } from '../../redux/stageService/stageSlice'

function TournamentsList ({ list, categories, venues, managers }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [tournamentsList, setTournamentsList] = useState([])

	useEffect(() => {
		if (list.length > 0) {
			// Создаем объект для группировки
			const groupedData = {}

			// Сортируем данные
			const sortedData = [...list].sort((a, b) => {
				const dateA = new Date(a.start_date)
				const dateB = new Date(b.start_date)

				// Сравниваем даты
				return dateA - dateB
			})

			// Группируем данные по годам и месяцам
			sortedData.forEach((item) => {
				const year = new Date(item.start_date).getFullYear().toString().padStart(4, '0')
				const month = new Date(item.start_date).toLocaleString('en', { month: '2-digit' })

				if (!groupedData[year]) {
					groupedData[year] = {}
				}

				if (!groupedData[year][month]) {
					groupedData[year][month] = []
				}

				groupedData[year][month].push(item)
			})

			setTournamentsList(groupedData)
		} else {
			setTournamentsList([])
		}
	}, [list])

	// Функция вывода категории
	const handleCategory = useCallback((item) => {
		const category = categories.filter((el) => {
			return item?.category_uid === el.uid
		})

		return category[0]?.name
	}, [categories])

	// Функция вывода площадки
	const handleVenues = useCallback((item) => {
		const venue = venues.filter((el) => {
			return item?.venue_uid === el.uid
		})

		return venue[0]?.address_string
	}, [venues])

	function getOrg(createdBy) {
		if (createdBy) {
			const org = managers.find(item => item.uid === createdBy)
			return `${org.last_name} ${org.first_name}`
		} else {
			return ''
		}
	}

	function gotToTournament(uid) {
		dispatch(setCurrentStageNumber(1))
		navigate(`/tournament/${uid}/draws/1`)
	}

	return (
		<>
			{
				Object.keys(tournamentsList)
					.sort((a, b) => b - a) // Инвертированный порядок годов
					.map((year) => (
						<React.Fragment key={year}>
							{
								Object.keys(tournamentsList[year])
									.sort((a, b) => b - a) // Инвертированный порядок месяцев
									.map((month) => (
										<React.Fragment key={month}>
											<TournamentYearBlock
												month={month}
												year={year}
											/>

											{
												tournamentsList[year][month]
													.slice() // Создаем копию массива для сохранения исходного порядка
													.sort((a, b) => b.start_date.localeCompare(a.start_date)) // Сортировка по дате в обратном порядке
													.map((item, index) => (
														<TournamentBlock
															key={index}
															date={item.start_date}
															tier={item.tier}
															tournamentName={item.name}
															participantsCount={item.participants_count}
															gender={item.gender}
															category={handleCategory(item)}
															type={item.type}
															status={item.status}
															createdBy={getOrg(item.created_by_uid)}
															onClick={() => gotToTournament(item.uid)}
															venues={handleVenues(item)}
														/>
													))
											}
										</React.Fragment>
									))
							}
						</React.Fragment>
					))
			}
		</>
	)
}

export default memo(TournamentsList)