import { useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import './TournamentParticipantsList.scss'
import { selectAuth, selectParticipants, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import {
	setDndBetweenIsOn,
	setNoSortedParticipants,
	setParsedDoubles,
	setParsedDoublesInitial,
	setParticipants,
	setUserChangedList
} from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { ReactComponent as DragIcon } from '../../icons/drag.svg'
import { ReactComponent as EditMan } from '../../icons/edit-person.svg'
import { ReactComponent as DoubleDndMode } from '../../icons/double_dnd_mode.svg'
import { ReactComponent as DoubleDndBetweenMode } from '../../icons/double_dnd_between_mode.svg'
import { ReactComponent as SortIcon } from '../../icons/players_sort.svg'
import { RANDOM, STRENGTH, TYPE_DOUBLES, TYPE_SINGLES } from '../../utils/constants'
import { getOriginalDouble, itemsChangedOrder } from '../../utils/functions2'
import Loader from '../../reusableComponents/Loader/Loader'
import DoublesDragDropList from '../DoublesDragDropList/DoublesDragDropList'
import SortPlayersModal from '../../reusableComponents/SortPlayersModal/SortPlayersModal'

function TournamentParticipantsList({ openOptions }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { authorized, guestCode } = useSelector(selectAuth)
	const {
		players,
		doubles,
		type,
		participants_count
	} = useSelector(selectTournament)
	const { settings = {} } = useSelector(selectTournamentOptions)
	const {
		participants,
		parsedDoubles,
		dndBetweenIsOn,
		noSortedParticipants
	} = useSelector(selectParticipants)
	const refContainer = useRef(null)

	const [processing, setProcessing] = useState(false)
	const [openSort, setOpenSort] = useState(false)
	const [sortMethod, setSortMethod] = useState('')

	function onDragEnd(result) {
		if (!authorized || !result.destination) {
			return
		}

		const { destination, source } = result

		const updatedParticipants = Array.from(participants)
		const [removed] = updatedParticipants.splice(source.index, 1)
		updatedParticipants.splice(destination.index, 0, removed)

		const dragIsDone = itemsChangedOrder(updatedParticipants, players || doubles)

		dispatch(setParticipants({ participants: updatedParticipants, type }))
		dispatch(setNoSortedParticipants(updatedParticipants)) // если после этого будет сортировка, то откатится к этому списку
		dispatch(setUserChangedList(dragIsDone))
	}

	function changeDoublesDndMode() {
		if (!authorized || guestCode || type !== TYPE_DOUBLES) return

		dispatch(setDndBetweenIsOn(!dndBetweenIsOn))
		setProcessing(true)

		if (!dndBetweenIsOn) {
			parseDoubles(participants)
		} else {
			compileDoubles()
		}

		setProcessing(false)
	}

	function parseDoubles(list) {
		// разбиваем пары на одиночных
		const parsedDoubles = list?.reduce((acc, double) => {
			const player1 = { ...double.player1 }
			player1.doubleUid = double.uid

			const player2 = { ...double.player2 }
			player2.doubleUid = double.uid

			acc.push(player1)
			acc.push(player2)

			return acc
		}, [])

		dispatch(setParsedDoubles(parsedDoubles))
		dispatch(setParsedDoublesInitial(parsedDoubles))
	}

	function compileDoubles() {
		// собираем пары вместе. каждые два человека это пара
		const compiledDoubles = []

		for (let i = 0; i < parsedDoubles?.length; i += 2) {
			const player = parsedDoubles[i]
			const nextPlayer = parsedDoubles[i + 1]

			let compiledDouble = {
				uid: player.uid,
				player1: player,
				player2: nextPlayer
			}

			const originalDouble = getOriginalDouble(doubles, { player1: player, player2: nextPlayer })

			if (originalDouble) {
				compiledDouble = { ...originalDouble }
			}

			compiledDoubles.push(compiledDouble)
		}

		dispatch(setParticipants({ participants: compiledDoubles }))
		dispatch(setNoSortedParticipants(compiledDoubles))  // если после этого будет сортировка, то откатится к этому списку
	}

	function dragItemStyle(provided, snapshot) {
		let style = {
			...provided.draggableProps.style
		}
		const backgroundColor = 'var(--palette-live-blue-3)'

		if (snapshot.isDragging) {
			style = {
				...style,
				backgroundColor,
				webkitBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				mozBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				boxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				borderBottom: 'none'
			}
		}

		return style
	}

	function sortParticipants({ last: number, sortMethod: method }) {
		setSortMethod(method)

		if (method === RANDOM) {
			if (!number || Number(number) === 0) {
				setOpenSort(false)
				return
			}

			randomLastParticipants(Number(number))
		} else if (method === STRENGTH) {
			let sorted = [...participants]

			sorted = sorted.sort((a, b) => {
				if (type === TYPE_SINGLES) {
					return Number(b.strength ?? 0) - Number(a.strength ?? 0)
				} else {
					const firstStrength = Number(a.player1?.strength ?? 0) + Number(a.player2?.strength ?? 0)
					const secondStrength = Number(b.player1?.strength ?? 0) + Number(b.player2?.strength ?? 0)
					return secondStrength - firstStrength
				}
			})

			updateLists(sorted)
		} else {
			dispatch(setParticipants({ participants: noSortedParticipants }))

			if (dndBetweenIsOn) {
				parseDoubles(noSortedParticipants)
			}
		}

		dispatch(setUserChangedList(true))
		setOpenSort(false)
	}

	function randomLastParticipants(number) {
		const selectedPlayers = participants.slice(-number)

		const shuffled = selectedPlayers.sort(() => Math.random() - 0.5)
		const leftNumber = participants?.length - number
		const leftParticipants = participants.slice(0, leftNumber)

		const unitedArray = [...leftParticipants, ...shuffled]
		updateLists(unitedArray)
	}

	function updateLists(updatedList) {
		dispatch(setParticipants({ participants: updatedList }))

		if (dndBetweenIsOn) {
			parseDoubles(updatedList)
		}
	}

	return (
		<>
			{!processing ?
				<div
					className="tournament-participants__list-wrapper"
					style={{ border: participants && participants?.length < 1 ? 'none' : '' }}
					ref={refContainer}
				>
					<div className="tournament-participants__title">
						<span className="tournament-participants__count">
							{participants_count}

							{authorized && !guestCode  && <span className="tournament-participants__separator"></span>}

							{authorized && !guestCode  && <span className="tournament-participants__count-max">
								{settings?.maxParticipants}
							</span>}
						</span>

						{t(authorized && !guestCode ? 'Saved' : 'Participate')}

						{authorized && !guestCode &&
							<div className="tournament-participants__icons">
								<div
									className="tournament-participants__icon"
									onClick={() => setOpenSort(true)}
								>
									<SortIcon />
								</div>

								<div
									className={`tournament-participants__icon ${type !== TYPE_DOUBLES ? 'disable' : ''}`}
									onClick={changeDoublesDndMode}
								>
									{type === TYPE_DOUBLES ?
										!dndBetweenIsOn ?
											<DoubleDndMode />
											:
											<DoubleDndBetweenMode />
										:
										<></>
									}
								</div>
							</div>
						}
					</div>

					{!dndBetweenIsOn ?
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="participants-list">
								{
									(provided) => (
										<div
											className="tournament-participants-list"
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{participants && participants?.map((el, index) =>
												<Draggable
													key={el.uid}
													draggableId={el.uid}
													index={index}
													isDragDisabled={authorized && !guestCode ? false : true}
												>
													{
														(provided, snapshot) => (
															<div
																className="tournament-participants__item"
																ref={provided.innerRef}
																{...provided.draggableProps}
																style={dragItemStyle(provided, snapshot)}
															>
																{
																	type === TYPE_SINGLES ?
																		<>
																			<p
																				className="tournament-participants__number"
																				onClick={() => openOptions(el)}
																			>
																				{index + 1}
																				{authorized && !guestCode && <EditMan className="tournament_participants__edit-man" />}
																			</p>

																			<div
																				// className={
																				// 	`tournament-participants__name ${
																				// 		el?.display_name && settings?.displayName
																				// 			? 'display-name' : ''
																				// 	}`
																				// }
																				className="tournament-participants__name"
																			>
																				{/* {el?.display_name && settings?.displayName &&
																				<p className="tournament-participants__display-name">
																					{`${el?.display_name}`}
																				</p>
																			} */}

																				<p {...provided.dragHandleProps}>
																					{`${el?.last_name} ${el.first_name}`}
																				</p>
																			</div>

																			{
																				authorized && !guestCode &&
																				<div
																					className="tournament_participants__block"
																					{...provided.dragHandleProps}
																				>
																					<p className="tournament_participants__strength">
																						{el.strength || ''}
																					</p>

																					<div className="tournament_participants__dnd">
																						<DragIcon />
																					</div>
																				</div>
																			}
																		</>
																		:
																		<>
																			<p
																				className="tournament-participants__number doubles"
																				onClick={() => openOptions(el)}
																			>
																				{index + 1}

																				{ authorized && !guestCode && <EditMan className="tournament_participants__edit-man" />}
																			</p>

																			<div className="tournament-participants__names" {...provided.dragHandleProps}>
																				<p className="tournament-participants__palyer--first">
																					{`${el?.player1?.last_name} ${el.player1?.first_name}`}
																				</p>

																				<p className="tournament-participants__palyer--second">
																					{`${el?.player2?.last_name} ${el?.player2?.first_name}`}
																				</p>
																			</div>

																			<div className="tournament-participants__palyers-strength" {...provided.dragHandleProps}>
																				<span className="tournament-participants__strength">
																					{el?.player1?.strength || ''}
																				</span>
																				<span className="tournament-participants__strength">
																					{el?.player2?.strength || ''}
																				</span>
																			</div>

																			{
																				authorized && !guestCode &&
																				<div
																					className="tournament_participants__dnd--doubles"
																					{...provided.dragHandleProps}
																				>
																					<DragIcon />
																				</div>
																			}
																		</>
																}
															</div>
														)
													}
												</Draggable>
											)}
											{provided.placeholder}
										</div>
									)
								}
							</Droppable>
						</DragDropContext>
						:
						<DoublesDragDropList />
					}
				</div>
				:
				<Loader />
			}

			{openSort && authorized && !guestCode &&
				<SortPlayersModal
					sortMethod={sortMethod}
					participants={dndBetweenIsOn ? parsedDoubles : participants}
					submit={sortParticipants}
					onClose={() => setOpenSort(false)}
					random
				/>
			}
		</>
	)
}

export default TournamentParticipantsList