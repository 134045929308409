import { memo } from 'react'

import './RadioButtonLight.scss'

function RadioButton({ value, name, onChange, checked, label, id, styles }) {
	return (
		<>
			<div
				className="radio-button-light"
				style={styles}
			>
				<input
					type="radio"
					className="radio-button__input"
					id={id}
					value={value}
					name={name}
					onChange={onChange}
					checked={checked}
				/>

				<label htmlFor={id}>
					{ label }
				</label>
			</div>
		</>
	)
}

export default memo(RadioButton)