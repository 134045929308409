import { Field, Formik } from 'formik'

import './SortPlayersModal.scss'
import Popup from '../../reusableComponents/Popup/Popup'
import RadioButtonLight from '../../reusableComponents/RadioButtonLight/RadioButtonLight'
import InputLight from '../../reusableComponents/InputLight/InputLight'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import { GENDER, NOSORT, RANDOM, STRENGTH } from '../../utils/constants'

function SortPlayersModal({ sortMethod, submit, participants, random, gender }) {
	return (
		<div className="sort-modal">
			<Popup title={'Сортировка'}>
				<Formik
					initialValues={{
						sortMethod: sortMethod,
						last: ''
					}}
				>{({ values, setFieldValue, setFieldError, errors }) => (
						<form className="sort-modal-form">
							<Field
								id="strength"
								name="strength"
								type="radio"
								value="strength"
								checked={values.sortMethod === STRENGTH}
								as={RadioButtonLight}
								label={'Балл'}
								onChange={(e) => {
									setFieldValue('sortMethod', STRENGTH)
									setFieldValue('last', '')
								}}
								onClick={() => {
									setFieldError('last', '')
								}}
							/>

							{gender &&
								<Field
									id="gender"
									name="gender"
									type="radio"
									value="gender"
									checked={values.sortMethod === GENDER}
									as={RadioButtonLight}
									label={'Пол'}
									onChange={(e) => {
										setFieldValue('sortMethod', GENDER)
										setFieldValue('last', '')
									}}
									onClick={() => {
										setFieldError('last', '')
									}}
								/>
							}

							{random &&
								<>
									<Field
										id="random"
										name="random"
										type="radio"
										value="random"
										checked={values.sortMethod === RANDOM}
										as={RadioButtonLight}
										label={'Случайно'}
										onChange={(e) => {
											setFieldValue('sortMethod', RANDOM)
										}}
										onClick={() => {
											setFieldError('last', '')
										}}
									/>

									<div className="sort-modal__manual-field">
										<Field
											value={values.last}
											onChange={(e) => {
												let value = e.target.value
												value = value.replace(/\D/g, '')

												if (value.length > 3 || (value.length > 1 && value[0] === '0')) {
													return
												} else if (Number(value) > participants.length) {
													setFieldValue('last', participants.length)
													return
												}

												setFieldValue('last', value)
											}}
											type="text"
											name="last"
											required={false}
											as={InputLight}
											error={errors.last}
											placeholder={'Последние'}
											onClick={() => {
												setFieldError('last', '')
											}}
										/>

										<RoundedButton
											title={'Все'}
											blue background={Number(values.last) !== participants?.length ? 'white' : ''}
											onClick={() => {
												setFieldValue('last', String(participants?.length))
												setFieldValue('sortMethod', RANDOM)
											}}
											type={'button'}
										/>
									</div>
								</>
							}

							<div className="sort-modal-buttons">
								<RoundedButton
									title={'Выбрать'}
									border type={'button'}
									onClick={() => {
										if (values.sortMethod === RANDOM && values.last.length < 1) {
											setFieldError('last', 'введите значение')
											return
										}

										submit(values)
									}}
								/>

								<RoundedButton
									title={'Отменить'}
									background={'white'}
									border
									type={'button'}
									onClick={() => {
										setFieldValue('sortMethod', NOSORT)
										submit({ ...values, sortMethod: NOSORT })
									}}
								/>
							</div>
						</form>
					)}
				</Formik>
			</Popup>
		</div>
	)
}

export default SortPlayersModal