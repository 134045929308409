import { TYPE_SINGLES } from '../../utils/constants'
import { itemsChangedOrder } from '../../utils/functions2'

function getChanges(init, updated, type) {
	const removed = []
	const added = []

	for (let i = 0; i < init.length; i++) {
		const participant = init[i]

		if (type === TYPE_SINGLES) {
			const exists = updated.some(item => item.uid === participant.uid)

			if (!exists) {
				removed.push(participant.uid)
			}
		} else {
			const saved = updated.some(item =>
				item.player1.uid === participant.player1.uid && item.player2.uid === participant.player2.uid
			)

			if (!saved) {
				removed.push(participant.uid)
			}
		}
	}

	for (let i = 0; i < updated.length; i++) {
		const participant = updated[i]

		if (type === TYPE_SINGLES) {
			const saved = init.some(item => item.uid === participant.uid)

			if (!saved) {
				added.push(participant.uid)
			}
		} else {
			const saved = init.some(item =>
				item.player1.uid === participant.player1.uid && item.player2.uid === participant.player2.uid
			)

			if (!saved) {
				added.push({
					player1: participant.player1.uid,
					player2: participant.player2.uid
				})
			}
		}
	}

	const seeded = itemsChangedOrder(init, updated, type)

	return { removed, added, seeded }
}

function saveIsAllowed(init, updated, type, permissions) {
	// сравниваем что сделал юзер и что ему разрешено в текущем состоянии турнира
	const { added, removed, seeded } = getChanges(init, updated, type)
	const { add: addEnable, remove: removeEnable, seed: seedEnable } = permissions

	const response = {
		allowed: false,
		added,
		removed,
		seeded
	}

	if ((!removeEnable && removed.length) > 0 || (!addEnable && added.length > 0) || (!seedEnable && seeded)) {
		return response
	}

	return { ...response, allowed: true }
}

function updateDoublesUids(participantsState, newParticipants) {
	// заменяем фэйковые uid пар на настоящие

	return participantsState.map(el => {
		const newDouble = newParticipants.find(item =>
			el.player1.uid === item.player1_uid && el.player2.uid === item.player2_uid
		)

		if (newDouble) {
			return {
				...el,
				uid: newDouble.uid
			}
		} else {
			return el
		}
	})
}

function filterRemovedParticipants(savedParticipants, updatedParticipants) {
	return savedParticipants.filter(item => updatedParticipants.some(el => item.uid === el.uid))
}

function listIsChanged(init, updated) {
	if (init.length > updated.length || init.length < updated.length) {
		return true
	}

	const orderChanged = itemsChangedOrder(init, updated)

	return orderChanged
}

export {
	saveIsAllowed,
	getChanges,
	updateDoublesUids,
	filterRemovedParticipants,
	listIsChanged
}