import { memo, Fragment, useMemo, useCallback, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'

import './PreStageRating.scss'
import { ReactComponent as DND } from '../../../icons/dnd.svg'
import { selectPlayersList, selectTournament } from '../../../redux/selectors'
import { setPlayersList } from '../../../redux/stageService/stageSlice'
import { TYPE_SINGLES } from '../../../utils/constants'
import DoubleButton from '../../../reusableComponents/DoubleButton/DoubleButton'
import RoundedButton from '../../../reusableComponents/RoundedButton/RoundedButton'
import { checkEquality, getParameters } from '../../../utils/functions'
import { useSavePrestageRatingMutation, useRecoverInitialRaitingMutation } from '../../../redux/stageService/stageApiSlice'

function PreStageRating({
	initialParticipantsPreStage
}) {
	const dispatch = useDispatch()
	const location = useLocation()
	const [savePrestageRating] = useSavePrestageRatingMutation()
	const [recoverRating] = useRecoverInitialRaitingMutation()
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const playersList = useSelector(selectPlayersList)
	const tournament = useSelector(selectTournament)
	const { type } = tournament

	const [requestIsActive, setRequestIsActive] = useState({
		save: false, recover: false
	})

	const isEqualePreStage = useMemo(() => {
		return checkEquality(playersList, initialParticipantsPreStage, true)
	}, [playersList, initialParticipantsPreStage])

	const handleSavePlayersPreStage = useCallback(async () => {
		setRequestIsActive(prev => {
			return {
				...prev,
				save: true
			}
		})

		// Извлекаем значения свойства nodeUid и помещаем их в новый массив
		const nodeUids = playersList.map(item => item.nodeUid)
		// Формируем объект
		const body = { 'node_uids': nodeUids }

		try {
			await savePrestageRating({
				tournament_uid: tournamentUid,
				stageNumber,
				body
			}).unwrap()

			setRequestIsActive(prev => {
				return {
					...prev,
					save: false
				}
			})
		} catch (e) {
			console.log('handleSavePlayersPreStage catch error', e)
		}
	}, [playersList, savePrestageRating,
		stageNumber, tournamentUid])

	const backPlayersPreStage = useCallback(() => {
		dispatch(setPlayersList(initialParticipantsPreStage))
	}, [initialParticipantsPreStage, dispatch])

	const recoverPlayersPreStage = useCallback(async () => {
		setRequestIsActive(prev => {
			return {
				...prev,
				recover: true
			}
		})
		try {
			await recoverRating({
				tournament_uid: tournamentUid,
				stageNumber
			}).unwrap()

			setRequestIsActive(prev => {
				return {
					...prev,
					recover: false
				}
			})
		} catch (e) {
			console.log('recoverRating catch error', e)
		}
	}, [recoverRating, stageNumber, tournamentUid])

	function onDragEndPreStage(result) {
		if (!result.destination) {
			return
		}

		const { destination, source } = result

		const updatedParticipants = Array.from(playersList)
		const [removed] = updatedParticipants.splice(source.index, 1)
		updatedParticipants.splice(destination.index, 0, removed)

		dispatch(setPlayersList(updatedParticipants))
	}

	function dragItemStyle(provided, snapshot) {
		const backgroundColor = 'var(--palette-live-grey-8)'
		const borderStyle = '1px solid var(--palette-live-grey-6)'
		const borderRadius = '11px'

		return {
			userSelect: 'none',
			backgroundColor: snapshot.isDragging ? backgroundColor : '',
			borderRadius: snapshot.isDragging ? borderRadius : '',
			border: snapshot.isDragging ? borderStyle : '',
			...provided.draggableProps.style
		}
	}

	function isPrestage() {
		const parts = location?.pathname?.split('/')
		return parts.includes('pre-stage')
	}

	return (
		<div
			className="pre-stage"
			style={{
				marginBottom: !isEqualePreStage ? '60px' : ''
			}}
		>
			<div className="pre-stage__container">
				<DragDropContext onDragEnd={onDragEndPreStage}>
					<Droppable droppableId="pre-stage__container">
						{
							(provided) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{
										playersList.map((item, index) => (
											<Draggable
												key={item.nodeUid}
												draggableId={item.nodeUid}
												index={index}
											>
												{
													(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}

															style={dragItemStyle(provided, snapshot)}
															className="pre-stage__participants-container"
														>
															<div className="pre-stage__participants-wrapper">
																{
																	type === TYPE_SINGLES ?
																		<Fragment>
																			<p className="pre-stage__participants-index">
																				{index + 1}
																			</p>

																			<p className="pre-stage__players-name">
																				{item?.participant?.formedName || item.name || '---'}
																			</p>

																			{item?.playerUid &&
																				<p className="pre-stage__participants-rating">
																					[{item?.rating}]
																				</p>
																			}
																		</Fragment>
																		:
																		<Fragment>
																			<p className="pre-stage__participants-index">
																				{index + 1}
																			</p>

																			{item.doubleUid ?
																				<div className="pre-stage__players-container">
																					<p className="pre-stage__players-name extra-margin-bottom">
																						{item?.participant.firstPlayerName || item.name}
																					</p>

																					<p className="pre-stage__players-name">
																						{item?.participant.secondPlayerName || item.name}
																					</p>
																				</div>
																				:
																				<p className="pre-stage__players-name">
																					{item.name || '---'}
																				</p>
																			}

																			{item.doubleUid &&
																				<p className="pre-stage__participants-rating">
																					[{item?.rating}]
																				</p>
																			}
																		</Fragment>
																}
															</div>

															<div
																className="drag-icon"
																{...provided.dragHandleProps}
															>
																<DND />
															</div>
														</div>
													)
												}
											</Draggable>
										))
									}

									{provided.placeholder}
								</div>
							)
						}
					</Droppable>
				</DragDropContext>
			</div>

			{
				!isEqualePreStage && isPrestage() &&
				<div className="pre-stage__button-wrapper">
					<DoubleButton
						titleBig="Сохранить"
						titleSmall="Отменить"
						typeSmall={'button'}
						typeBig={'button'}
						onClickBig={() => handleSavePlayersPreStage()}
						onClickSmall={() => backPlayersPreStage()}
						loading={requestIsActive.save}
						fixed
					/>
				</div>
			}

			<div className="pre-stage__restore-wrapper">
				<span className="pre-stage__restore-title">
					{
						stageNumber < 2 ?
							'Посев'
							:
							'Результат предыдущего этапа'
					}
				</span>

				<RoundedButton
					title="Восстановить"
					background="white"
					border
					onClick={() => recoverPlayersPreStage()}
					loading={requestIsActive.recover}
				/>
			</div>
		</div>
	)
}

export default memo(PreStageRating)