import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ReactComponent as RemoveSet } from '../../icons/cross_set.svg'
import { NEW_SET_ADVANCE, NEW_SET_BASIC } from '../../utils/constants'
import { setActiveSetIndex, setMatchData } from '../../redux/matchService/matchSlice'
import { selectMatch, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { getActiveSet } from '../../utils/functions2'
import Popup from '../../reusableComponents/Popup/Popup'
import Input from '../../reusableComponents/Input/Input'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'

import { getNameForPlaceholder, getTiebreakPoints } from './externalFunctions'

function MatchSets({
	changeSlidesInSwiper
}) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { type } = useSelector(selectTournament)
	const { tiebreak, activeSetIndex, match } = useSelector(selectMatch)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const [popupOpened, setPopupOpened] = useState(false)
	const [firstInput, setFirstInput] = useState(0)
	const [secondInput, setSecondInput] = useState(0)

	const handleAddSet = useCallback(() => {
		const newSet = !options?.match?.advanceLevel ? NEW_SET_BASIC : NEW_SET_ADVANCE
		const addedSet = {
			...newSet,
			'order_number': match.sets.length + 1,
		}

		const sets = [...match.sets, addedSet]
		const lastSetIndex = sets.length - 1

		dispatch(setActiveSetIndex(lastSetIndex))
		dispatch(setMatchData({...match, sets }))

		changeSlidesInSwiper(sets, lastSetIndex, tiebreak)
	}, [match, changeSlidesInSwiper, tiebreak, options?.match?.advanceLevel,dispatch])

	const handleRemoveSet = useCallback((index) => {
		const sets = [...match.sets]
		sets.splice(index, 1)

		dispatch(setMatchData({...match, sets }))

		const lastSetIndex = sets.length > 0 ? sets.length - 1 : 0
		dispatch(setActiveSetIndex(lastSetIndex))
		changeSlidesInSwiper(sets, lastSetIndex, tiebreak)
	}, [match, tiebreak, changeSlidesInSwiper, dispatch])

	const handleSavePopupScores = useCallback(() => {
		const updatedMatch = { ...match }
		const sets = [...updatedMatch.sets]
		let activeSet = getActiveSet(updatedMatch.sets, activeSetIndex)

		if (tiebreak) {
			activeSet = {
				...activeSet,
				tie_break_score1: Math.abs(Number(firstInput)),
				tie_break_score2: Math.abs(Number(secondInput))
			}
		} else {
			activeSet = {
				...activeSet,
				score1: Math.abs(Number(firstInput)),
				score2: Math.abs(Number(secondInput))
			}
		}

		sets[activeSetIndex] = activeSet
		updatedMatch.sets = sets
		dispatch(setMatchData(updatedMatch))
		setPopupOpened(false)
	}, [activeSetIndex, tiebreak, match, firstInput, secondInput, dispatch])

	const openPopup = useCallback((index) => {
		const updatedMatch = match

		const activeSet = getActiveSet(updatedMatch.sets, index)
		const score1 = activeSet.score1
		const score2 = activeSet.score2
		const tiebreak1 = activeSet.tie_break_score1
		const tiebreak2 = activeSet.tie_break_score2

		if (!tiebreak) {
			setFirstInput(score1)
			setSecondInput(score2)
		} else {
			setFirstInput(tiebreak1 ? tiebreak1 : 0)
			setSecondInput(tiebreak2 ? tiebreak2 : 0)
		}

		setPopupOpened(true)
	}, [match, tiebreak])

	const handleSetClick = useCallback((index) => {
		if (index === activeSetIndex) {
			openPopup(index)
		} else {
			dispatch(setActiveSetIndex(index))
			changeSlidesInSwiper(match.sets, index, tiebreak)
		}
	}, [activeSetIndex, openPopup, changeSlidesInSwiper, match, tiebreak, dispatch])

	function getInputScore(vaule) {
		let stringValue = String(vaule)
		if (stringValue.length <= 3) {
			return stringValue ? Number(vaule) : stringValue
		} else {
			stringValue = stringValue.slice(0, 3)
			return Number(stringValue)
		}
	}

	const closePopup = useCallback(() => {
		setPopupOpened(false)
	}, [])

	const getSetStyle = useCallback((index) => {
		if (activeSetIndex === index) {
			return {
				borderRadius: '50px',
				border: '2px solid var(--palette-live-grey-6)'
			}
		} else {
			return {}
		}
	}, [activeSetIndex])

	return (
		<>
			<div className="match__sets">
				{match &&
					<>
						{
							match?.sets && match.sets.length > 0 &&
							match.sets.map((set, index) =>

								<div key={index} className="match__set-wrapper">
									{options?.match?.setsCount > 1 ?
										activeSetIndex === index &&
										<div className="match__set-nuber-container">
											<p className="match__set-nuber">{index + 1}</p>
										</div>
										: ''
									}

									<div
										style={getSetStyle(index)}
										className="match__set-container"
										onClick={() => handleSetClick(index, match.sets)}
									>
										<div className={
											!tiebreak && activeSetIndex === index
												? 'match__set-score' : 'match__set-score--inactive'
										}
										>
											<p className="match__set-points">
												{set.score1}
											</p>
											<p
												className={
													tiebreak && activeSetIndex === index ?
														'match__set-tiebreak' : 'match__set-tiebreak--inactive'
												}
											>
												{getTiebreakPoints(set.tie_break_score1, set.tie_break_score2, index, activeSetIndex, tiebreak)}
											</p>
										</div>

										<p>:</p>

										<div
											className={
												!tiebreak && activeSetIndex === index ?
													'match__set-score' : 'match__set-score--inactive'
											}
										>
											<p className="match__set-points">
												{set.score2}
											</p>

											<p
												className={
													tiebreak && activeSetIndex === index ?
														'match__set-tiebreak' : 'match__set-tiebreak--inactive'
												}
											>
												{getTiebreakPoints(set.tie_break_score2, set.tie_break_score1, index, activeSetIndex, tiebreak)}
											</p>
										</div>
									</div>

									<div className="match__set-button-deletion">
										{activeSetIndex === index ?
											<button
												type="button"
												onClick={() => handleRemoveSet(index)}
											>
												<RemoveSet className="match__set-icon-deletion" />
											</button>
											: ''
										}
									</div>

								</div>
							)
						}
					</>
				}

				{match && match?.sets && match?.sets.length < options?.match?.setsCount &&
					<button
						className="match__sets-button"
						onClick={handleAddSet}>
						{'Добавить сет'}
					</button>
				}
			</div>

			{popupOpened &&
				<Popup title={tiebreak ? t('Tiebreak') : ''} leftTitle>
					<div className="match__score-modal">
						<Input
							type={'number'}
							value={firstInput}
							onChange={(e) => setFirstInput(getInputScore(e.target.value))}
							name={'firstParticipantPoints'}
							placeholder={getNameForPlaceholder(match.side1, type)}
						/>

						<Input
							type={'number'}
							value={secondInput}
							onChange={(e) => setSecondInput(getInputScore(e.target.value))}
							name={'secondParticipantPoints'}
							placeholder={getNameForPlaceholder(match.side2, type)}
						/>

						<div className="match__popup-container">
							<div className="match__popup-button">
								<RoundedButton title={t('Cancel')} onClick={closePopup} border />
							</div>

							<div className="match__popup-button">
								<RoundedButton
									background={'white'}
									title={t('Save')}
									onClick={handleSavePopupScores}
									border
								/>
							</div>
						</div>
					</div>
				</Popup>
			}
		</>
	)
}

export default MatchSets