import { apiSlice } from '../apiSlice'

import { setCommunity } from './communitySlice'

export const communityApi = apiSlice.injectEndpoints({
	reducerPath: 'communityApi',
	tagTypes: ['Community'],
	endpoints: (build) => ({
		getCommunity: build.query({
			query: ({community_uid}) => `/tennis/communities/${community_uid}/`,
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(setCommunity(data))
				} catch (e) {
					console.log('getCommunity catch error', e)
				}
			},
			providesTags: ['Community']
		}),
		addTournament: build.mutation({
			query: (body) => ({
				url: '/tennis/tournaments/',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Community']
		}),
		removeTournament: build.mutation({
			query: ({ tournament_uid }) => ({
				url: `tennis/tournaments/${tournament_uid}/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Community']
		}),
		updateCommunity: build.mutation({
			query: ({ community_uid, body }) => ({
				url: `/tennis/communities/${community_uid}/`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: ['Community']
		}),
		removeCommunity: build.mutation({
			query: ({ community_uid }) => ({
				url: `/tennis/communities/${community_uid}/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Community']
		})
	})
})

export const {
	useAddTournamentMutation,
	useGetCommunityQuery,
	useRemoveTournamentMutation,
	useUpdateCommunityMutation,
	useRemoveCommunityMutation
} = communityApi