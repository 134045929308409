/* eslint-disable indent */
import React, { useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import './NotPlayingGridPrint.scss'
import { selectTournament } from '../../redux/selectors'
import { TYPE_SINGLES } from '../../utils/constants'
import { generateGroupOrLevelsName } from '../../utils/functions'
import { processGroup } from '../../utils/functions2'

function NotPlayingGridPrint({ group }) {
	const [grid, setGrid] = useState([])
	const { type, players, doubles } = useSelector(selectTournament)

	useEffect(() => {
		const gridArr = processGroup(group, players, doubles)
		setGrid(gridArr)
	}, [doubles, players, group])

	const participantsMap = useMemo(() => {
		return new Map((players || doubles)?.map(item =>
			[item.uid, item]
		))
	}, [players, doubles])

	function getPlayerName(player) {
		if (type === TYPE_SINGLES) {

			return participantsMap.get(player.playerUid)?.formedName || player.name
		} else {
			return <>
				<p className="no-play-print-grid__match-double-player-wrapper">
					{ participantsMap.get(player.doubleUid)?.player1?.formedName || player.name }
				</p>

				<p className="no-play-print-grid__match-double-player-wrapper">
					{ participantsMap.get(player.doubleUid)?.player2?.formedName || '' }
				</p>
			</>
		}
	}

	return (
		<div className="no-play-print__grid-wrapper">
			<table className="no-play-print-grid__table">
				<thead>
					<tr>
						<th>
							{ generateGroupOrLevelsName(group.order_number - 1) }
						</th>
					</tr>
				</thead>

				<tbody>
					{
						grid.map((player, index) => (
							<tr key={player.uid} className="no-play-print-grid__row-table">
								<td className="no-play-print-grid__match-player-wrapper">
									{ getPlayerName(player) }
								</td>

								<td className="no-play-print-grid__index">
									{ index + 1 }
								</td>
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	)
}

export default NotPlayingGridPrint