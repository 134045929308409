import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { selectAuth } from '../../redux/selectors'
import { getLocalStorageItem } from '../../utils/functions'
import { ACCESS, ACCESS_EXPIRES_AT, REFRESH, REFRESH_EXPIRES_AT } from '../../utils/constants'

function RequireAuth() {
	const location = useLocation()
	const { authorized } = useSelector(selectAuth)
	const [auth, setAuth] = useState(authorized ? true : false)

	useEffect(() => {
		if (!authorized) {
			const access = getLocalStorageItem(ACCESS)
			const refresh = getLocalStorageItem(REFRESH)
			const accessExpiresAt = getLocalStorageItem(ACCESS_EXPIRES_AT)
			const refreshExpiresAt = getLocalStorageItem(REFRESH_EXPIRES_AT)

			if (access && refresh && accessExpiresAt && refreshExpiresAt) {
				setAuth(true)
			} else {
				setAuth(false)
			}
		} else {
			setAuth(true)
		}
	}, [authorized])

	return (
		<>
			{
				auth ?
					<Outlet />
					:
					<Navigate to={'/auth?redirect=' + location.pathname} />
			}
		</>
	)
}

export default RequireAuth