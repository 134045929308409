import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { useSelector, useDispatch } from 'react-redux'

import './PlayerForm.scss'
import {
	useAddPlayerMutation,
	useUpdatePlayerMutation,
	useRemovePlayerMutation,
	useGetPlayerQuery
} from '../../redux/playersService/playersApiSlice'
import Header from '../../reusableComponents/Header/Header'
import Input from '../../reusableComponents/InputLight/InputLight'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import RadioButton from '../../reusableComponents/RadioButtonLight/RadioButtonLight'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import StringButton from '../../reusableComponents/StringButton/StringButton'
import Loader from '../../reusableComponents/Loader/Loader'
import MySelect from '../../reusableComponents/Select/Select'

import { getParameters, isValidEmail } from '../../utils/functions'
import { selectCommunity, selectParticipants, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { setCreatedPlayers, setNoSortedParticipants, setParticipants, setUserChangedList } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { TYPE_SINGLES } from '../../utils/constants'
import { getParticipantsWithNewPlayer, replacePhone } from '../../utils/functions2'

import { strengthLevels } from './strengthLevels'

function PlayerForm() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { tournamentParams, uid } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const [createPlayer] = useAddPlayerMutation()
	const [updatePlayer] = useUpdatePlayerMutation()
	const [removePlayers] = useRemovePlayerMutation()

	const { communityUid, communityName } = useSelector(selectCommunity)
	const { participants = [] } = useSelector(selectParticipants)
	const { createdPlayers } = useSelector(selectParticipants)
	const { type } = useSelector(selectTournament)
	const { settings = {} } = useSelector(selectTournamentOptions)

	const [requestIsActive, setRequesIsActive] = useState(false)
	const [focus, setFocus] = useState(true)

	const { data = {}, isLoading, error } = useGetPlayerQuery({ uid }, { skip: !uid })

	function checkIfStringHasOnlyLetters(str) {
		const pureStr = str.trim()
		return /^[a-zA-Zа-яА-ЯёЁ\s]+$/.test(pureStr)
	}

	// Валидируем LastName
	function validateLastName(valueLastName, setFieldError) {
		const str = valueLastName.replace(' ', '')
		const err = t('Enter last name')

		if (!str) {
			setFieldError('lastName', err)
			return false
		} else {
			return true
		}
	}

	// Валидируем BirthDate
	function validateBirthDate(valueBirthDate, setFieldError) {
		const parts = valueBirthDate.split('-')
		const year = parts[0]

		if (year.startsWith('0')) {
			setFieldError('birthDate', 'Значение не действительно')
			return false
		} else {
			return true
		}
	}

	// Валидируем BirthYear
	function validateBirthYear(valueBirthYear, setFieldError) {
		const string = valueBirthYear.toString()

		if (string) {
			if (string.length > 4 || string?.length < 4) {
				setFieldError('birthYear', 'Некорректный год')
				return false
			}
		}

		return true
	}

	// Валидируем Email
	function validateEmail(valueEmail, setFieldError) {
		if (valueEmail.length > 0) {
			if (isValidEmail(valueEmail)) {
				return true
			} else {
				setFieldError('email', t('Invalid email'))
				return false
			}
		} else {
			return true
		}
	}

	// Валидируем Phone
	function validatePhone(valuePhone, setFieldError) {
		const phoneNumberRegex = /^((\+7|8)?(9\d{9}))$/g

		if (valuePhone) {
			if (phoneNumberRegex.test(valuePhone) && valuePhone.length >= 10) {
				return true
			} else {
				setFieldError('phone', t('Invalid phone number'))
				return false
			}
		} else {
			return true
		}
	}

	function validateFields(values, setFieldError) {
		const { lastName, birthDate, birthYear, phone, email } = values

		const isLastNameValid = validateLastName(lastName, setFieldError)
		const isBirthDateValid = validateBirthDate(birthDate, setFieldError)
		const isBirthYearValid = validateBirthYear(birthYear, setFieldError)
		const isPhoneValid = validatePhone(phone, setFieldError)
		const isEmailValid = validateEmail(email, setFieldError)

		if (!isLastNameValid || !isBirthDateValid || !isBirthYearValid || !isPhoneValid || !isEmailValid) {
			return false
		} else {
			return true
		}
	}

	async function sendDataForm(values, setFieldError) {

		if (!validateFields(values, setFieldError)) {
			return
		}

		setRequesIsActive(true)

		const birthYear = values?.birthDate ?
			values?.birthDate.slice(0, 4)
			:
			values?.birthYear &&
			values?.birthYear

		try {
			const body = {
				'gender': values?.gender,
				'last_name': values?.lastName,
				'first_name': values?.firstName,
				'middle_name': values?.middleName,
				'strength': values?.strength?.value ? Number(values?.strength?.value) : null,
				'birth_date': values?.birthDate ? values?.birthDate : null,
				'birth_year': birthYear.length > 0 ? birthYear : null,
				'email': values?.email,
				'mobile': replacePhone(values?.phone),
				'community_uids': [communityUid]
			}

			let response = null

			if (uid) {
				response = await updatePlayer({ body, player_uid: data?.uid }).unwrap()
			} else {
				response = await createPlayer({ body }).unwrap()
			}

			if (response?.error?.status === 401) {
				setRequesIsActive(false)
				navigate('/')
			} else if (response?.error) {
				setRequesIsActive(false)
				return
			} else {
				if (tournamentUid && participants?.length < settings?.maxParticipants && type === TYPE_SINGLES) {
					// если добавление идет через турнир
					const { newPlayer, participants: updatedParticipants } = getParticipantsWithNewPlayer(response, participants)

					// устанавливаем, что юзер произвел добавление игрока в список участника
					dispatch(setUserChangedList(true))

					// в текущий список участников попадет только одиночный игрок
					dispatch(setParticipants({ participants: updatedParticipants }))
					dispatch(setNoSortedParticipants(updatedParticipants)) // если после этого будет сортировка участников, то откатится к этому списку
					dispatch(setCreatedPlayers([...createdPlayers, newPlayer]))
				}

				setRequesIsActive(false)

				navigate(tournamentUid ? `/tournament/${tournamentUid}/participants` : '/players')
			}
		} catch (e) {
			setRequesIsActive(false)
			console.log('createPlayer catch error', e)
		}
	}

	async function deletePlayer() {
		try {
			const response = await removePlayers({ player_uid: uid }).unwrap()

			if (response?.error) {
				return
			}

			navigate('/players')
		} catch (e) {
			console.log('removePlayers catch error', e)
		}
	}

	if (error?.status === 404) {
		return <Navigate to={'/players'}/>
	}

	return (
		<>
			<Header
				communityName={communityName}
				title={uid ? t('ИГРОКИ') : t('Add player')}
				back
				backUrl={tournamentUid ? `/${tournamentUid}/participants` : '/players'}
				border
			/>

			<main className="player-form">
				{
					isLoading ?
						<Loader />
						:
						<>
							<Formik
								initialValues={{
									lastName: data?.last_name || '',
									firstName: data?.first_name || '',
									middleName: data?.middle_name || '',
									gender: data?.gender || null,
									birthDate: data?.birth_date || '',
									birthYear: data?.birth_year || '',
									email: data?.email || '',
									phone: data?.mobile || '',
									strength: data.strength ? { label: data?.strength, value: data?.strength } : { label: '-', value: null }
								}}
								onSubmit={(values, { setFieldError }) => {
									sendDataForm(values, setFieldError)
								}}
							>
								{({ values, errors, touched, handleSubmit, handleChange, setFieldError, setFieldValue }) => (
									<form
										onSubmit={handleSubmit}
										noValidate
										className="player-form__form"
										onClick={() => setFocus(false)}
									>
										<Field
											value={values.lastName}
											init={focus}
											onChange={(e) => {
												const value = e.target.value.replace(/\s+/g, ' ')

												if (!checkIfStringHasOnlyLetters(value) && value.trim()) {
													// допускаем только буквы если есть какие то знаки в строке
													return
												}

												handleChange(e)
											}}
											type="text"
											name="lastName"
											as={Input}
											placeholder={t('Last name') + `${uid ? '' : ' *'}`}
											error={errors.lastName}
											touched={touched.lastName}
											maxLength={30}
											onClick={() => {
												setFieldError('lastName', '')
											}}
										/>

										<Field
											value={values.firstName}
											onChange={(e) => {
												const value = e.target.value.replace(/\s+/g, ' ')

												if (!checkIfStringHasOnlyLetters(value) && value.trim()) {
													// допускаем только буквы если есть какие то знаки в строке
													return
												}

												handleChange(e)
											}}
											type="text"
											name="firstName"
											as={Input}
											placeholder={t('First name')}
											error={errors.firstName}
											touched={touched.firstName}
											maxLength={30}
											onClick={() => {
												setFieldError('firstName', '')
											}}
										/>

										<Field
											value={values.middleName}
											onChange={(e) => {
												const value = e.target.value.replace(/\s+/g, ' ')

												if (!checkIfStringHasOnlyLetters(value) && value.trim()) {
													// допускаем только буквы если есть какие то знаки в строке
													return
												}

												handleChange(e)
											}}
											type="text"
											name="middleName"
											placeholder={t('Middle name')}
											touched={touched.middleName}
											maxLength={30}
											as={Input}
										/>

										<Field
											id="strength"
											value={values.strength}
											onChange={(option) => {
												setFieldValue('strength', option)
											}}
											name="strength"
											placeholder={'Балл'}
											touched={touched.strength}
											defaultValue={null}
											options={strengthLevels}
											as={MySelect}
										/>

										<Field
											value={values.birthDate}
											onChange={handleChange}
											type="date"
											name="birthDate"
											placeholder={t('Birth date')}
											error={errors.birthDate}
											touched={touched.birthDate}
											as={Input}
											onClick={() => {
												setFieldError('birthDate', '')
											}}
										/>

										<Field
											value={
												values.birthDate.slice(0, 4) ?
													values.birthDate.slice(0, 4)
													:
													values.birthYear
											}
											onChange={e => {
												if (e.target.value.length > 4) {
													return
												}

												handleChange(e)
											}}
											type="number"
											error={errors.birthYear}
											name="birthYear"
											placeholder={t('Birth year')}
											as={Input}
											touched={touched.birthYear}
											readOnly={values.birthDate}
											maxLength={4}
											onClick={() => {
												setFieldError('birthYear', '')
											}}
										/>

										<Field
											value={values.email}
											onChange={handleChange}
											type="email"
											error={errors.email}
											touched={touched.email}
											name="email"
											placeholder={t('Email')}
											as={Input}
											onClick={() => {
												setFieldError('email', '')
											}}
										/>

										<Field
											value={values.phone}
											onChange={handleChange}
											type="text"
											name="phone"
											error={errors.phone}
											touched={touched.phone}
											placeholder={t('Phone number')}
											as={Input}
											onClick={() => {
												setFieldError('phone', '')
											}}
										/>

										<div className="player-form__radio-buttons">
											<Field
												id="player-gender__male"
												name="gender"
												type="radio"
												value="male"
												checked={values.gender === 'male'}
												as={RadioButton}
												label={t('Male')}
												onChange={handleChange}
												borderBottom
											/>

											<Field
												id="player-gender__female"
												name="gender"
												type="radio"
												value="female"
												checked={values.gender === 'female'}
												as={RadioButton}
												label={t('Female')}
												onChange={handleChange}
											/>
										</div>

										<div className="player-form__submit">
											<RoundedButton
												type={'submit'}
												title={t('Save')}
												loading={requestIsActive}
											/>
										</div>
									</form>
								)}
							</Formik>
						</>
				}

				{
					uid &&
					<div className="player-form__delete-button">
						<StringButton
							onClick={deletePlayer}
							title={t('Delete player')}
						/>
					</div>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default PlayerForm