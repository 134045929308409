import { memo } from 'react'

import { ReactComponent as RollingIcon } from '../../icons/rolling.svg'
import './RoundedButton.scss'

function RoundedButton({ title, background, onClick, type, marginBottom, border, disabled, loading, shadow, blue }) {
	const WHITE = 'white'
	const GREY = 'grey'
	const DARK_GREY_COLOR = 'var(--palette-live-grey-0)'
	const WHITE_COLOR = 'var(--palette-live-bg-0-white)'
	const DISABLED_TEXT_COLOR = 'var(--palette-live-grey-6)'
	const LIGHT_GREY_COLOR = 'var(--palette-live-grey-11)'
	const BLUE = 'var(--palette-live-blue)'
	const BORDER_STYLE = '1px solid ' + DARK_GREY_COLOR

	function buttonStyle() {
		let style = {
			marginBottom: marginBottom ? '7px' : '',
			border: border && !disabled ? BORDER_STYLE : 'none',
			padding: loading ? '10px 0' : '13px 0',
			backgroundColor: DARK_GREY_COLOR,
			color: DISABLED_TEXT_COLOR
		}

		if (background === WHITE) {
			style = {
				...style,
				backgroundColor: WHITE_COLOR
			}
		} else if (background === GREY || disabled) {
			style = {
				...style,
				backgroundColor: LIGHT_GREY_COLOR
			}
		} else if (blue) {
			style = {
				...style,
				backgroundColor: BLUE
			}
		}

		if ((background === WHITE && !blue)  || (background === GREY && !disabled)) {
			style = {
				...style,
				color: DARK_GREY_COLOR
			}
		} else if (!disabled && background !== WHITE) {
			style = {
				...style,
				color: WHITE_COLOR
			}
		} else if (background === WHITE && blue) {
			style = {
				...style,
				color: BLUE
			}
		}

		if (blue) {
			style = {
				...style,
				border: '1px solid ' + BLUE
			}
		}

		return style
	}

	const loader =
		<div className="rounded-button__loader">
			<RollingIcon
				className="rounded-button__loader-icon"
				style={{
					stroke: background === GREY || background === WHITE
						? 'var(--palette-live-grey-0)' : 'var(--palette-live-bg-0-white)'
				}}
			/>
		</div>

	return (
		<button
			className={`rounded-button ${shadow ? 'shadow' : ''}`}
			style={buttonStyle()}
			onClick={disabled ? undefined : onClick}
			type={type}
		>
			{loading ? loader : title}
		</button>
	)
}

export default memo(RoundedButton)