import { BUILD, DRAWS, GENERATION, SCHEDULE, NODE_TYPE_IN, excludedErrors } from './constants'

function processParticipants(array) {
	function hasDuplicates(arr) {
		const seen = new Set()
		for (const item of arr) {
			if (seen.has(item.uid)) {
				return true
			}
			seen.add(item.uid)
		}
		return false
	}

	if (hasDuplicates(array)) {
		return removeDuplicates(array)
	} else {
		return array
	}
}

function removeDuplicates(arr) {
	const seen = new Set()
	return arr.filter(item => {
		if (seen.has(item.uid)) {
			return false
		}
		seen.add(item.uid)
		return true
	})
}

function getExtraPath(location, number) {
	let extraPath = ''

	const currentPathParts = location?.pathname.split('/')
	const screenPath = `/${currentPathParts[1]}/${currentPathParts[2]}/${currentPathParts[3]}/${number}`

	if (currentPathParts?.includes(DRAWS) || currentPathParts?.includes(SCHEDULE)) {
		return screenPath
	} else {
		extraPath =
			currentPathParts?.includes(BUILD)
				? BUILD : currentPathParts?.includes(GENERATION)
					? GENERATION : extraPath

		return screenPath + '/' + extraPath
	}
}

function levelsHaveGroups(levels) {
	if (!levels) return
	return levels.some(lvl => lvl?.groups?.length > 0)
}

function itemsChangedOrder(participants, immutableState) {
	let newArray = participants
	let savedArray = immutableState

	if (participants.length > immutableState.length) {
		newArray = newArray.slice(0, immutableState.length)
	} else if (immutableState.length > participants.length) {
		savedArray = savedArray.slice(0, participants.length)
	}

	return checkParticipantsOrder(newArray, savedArray)
}

function checkParticipantsOrder(participants, immutableState) {
	const orderIsChanged = (
		immutableState?.length > 0 &&
		immutableState.some((state, i) => {
			if (!participants[i]?.player1) {
				return state?.uid !== participants[i]?.uid
			} else {
				return state.player1.uid !== participants[i].player1.uid || state.player2.uid !== participants[i].player2.uid
			}
		})
	)

	return orderIsChanged
}

function getActiveSet(sets, activeSetIndex) {
	return { ...sets[activeSetIndex] }
}

function getParticipantsWithNewPlayer(player, participants) {
	const newPlayer = {
		first_name: player.first_name,
		last_name: player.last_name,
		middle_name: player.middle_name,
		display_name: null,
		gender: player.gender,
		uid: player.uid,
		birth_date: player.birth_date,
		birth_year: player.birth_year,
	}

	return { participants: [...participants, newPlayer], newPlayer }
}

// Функция для обработки групп и игроков
function processGroup(group, players, doubles) {
	if (Object.keys(group).length === 0) {
		return []
	}

	// Получаем имена игроков по группам
	const gridArr = group.nodes.flatMap(node => {
		let playerObject

		if (node.type === NODE_TYPE_IN) {
			if (node.double_uid === null && node.player_uid === null) {
				// Если нет double_uid и player_uid
				return node
			} else if (players) {
				// Одиночный
				playerObject = players?.find(player => player.uid === node.player_uid)

				if (playerObject) {
					playerObject = { ...playerObject, name: node.name, playerUid: node.player_uid }
				}
			} else {
				// Парный
				playerObject = doubles?.find(doublesPlayer => doublesPlayer.uid === node.double_uid)

				if (playerObject) {
					playerObject = { ...playerObject, name: node.name, doubleUid: node.double_uid }
				}
			}

			if (playerObject) {
				// Возвращаем объект с установленным nodeUid
				return {
					...playerObject,
					nodeUid: node?.uid
				}
			}
		}

		return null // Возвращаем null для элементов, которые не прошли фильтр
	}).filter(player => player !== null)

	return gridArr
}

function isExeptedError(errorData = {}) {
	if (errorData?.detail || errorData?.email || errorData?.mobile) {

		const errorText = errorData?.detail ? errorData?.detail :
			errorData?.email ? errorData?.email[0] :
				errorData?.mobile ? errorData?.mobile[0] : ''

		return excludedErrors.some(item => item.toLowerCase().replace(/ +/g, '') === errorText.toLowerCase().replace(/ +/g, ''))
	}

	return false
}

// Заменяем на нужный нам формат Phone
function replacePhone(phoneNuber) {
	if (phoneNuber.startsWith('8')) {
		return `+7${phoneNuber.slice(1)}`

	} else if (phoneNuber.startsWith('9')) {
		return `+7${phoneNuber}`

	} else if (phoneNuber.startsWith('+7')) {
		return `${phoneNuber}`
	}
}

function getOriginalDouble(savedArr, double) {
	return savedArr.find(item =>
		double.player1.uid === item.player1.uid && double.player2.uid === item.player2.uid
	)
}

function nodeInGame(group, node) {
	return group?.matches.some(item => 
		(item?.side1_uid === node?.nodeUid || item?.side2_uid === node?.nodeUid) && item?.sets?.length > 0
	)
}

function nodeIsPlaying(node, stageState) {
	return stageState?.levels?.some(lvl => 
		lvl?.groups?.some(grp => grp.nodes?.some(item => {
			return item.dependsOnUid === node.nodeUid && nodeInGame(grp, item)
		}))
	)
}

function isCreateComunityRequest(request) {
	const createCommunityRequst = '/tennis/communities/'
	const { url, method } = request

	if (url === createCommunityRequst && method === 'POST') {
		return true
	} else {
		return false
	}
}

function isNumeric(value) {
	if (typeof value === 'number') {
		return true
	} else if (typeof value === 'string') {
		const hasLeadingSpace = /^\s/.test(value)
		const hasInternalSpace = /\s/.test(value)

		return !hasInternalSpace && !hasLeadingSpace && !isNaN(value)
	} else {
		return false
	}
}

export {
	processParticipants,
	getExtraPath,
	levelsHaveGroups,
	itemsChangedOrder,
	getActiveSet,
	getParticipantsWithNewPlayer,
	processGroup,
	isExeptedError,
	replacePhone,
	getOriginalDouble,
	nodeInGame,
	nodeIsPlaying,
	isCreateComunityRequest,
	isNumeric
}