import { memo } from 'react'

import './EmptyParticipant.scss'

function EmptyParticipant() {
	return (
		<span className="empty-participant"></span>
	)
}

export default memo(EmptyParticipant)