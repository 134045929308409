import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './NoContent.scss'
import { ReactComponent as StructureIcon } from '../../icons/structure.svg'
import { ReactComponent as RoundPlusIcon } from '../../icons/round-plus.svg'
import { ReactComponent as MagicStickIcon } from '../../icons/magic_stick.svg'
import { ReactComponent as BuildIcon } from '../../icons/build__stage.svg'
import { ReactComponent as ParticipantsIcon } from '../../icons/participants.svg'
import Popup from '../Popup/Popup'
import RoundedButton from '../RoundedButton/RoundedButton'
import { ReactComponent as RollingIcon } from '../../icons/rolling.svg'

import { selectAuth, selectInitialStage, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { getParameters } from '../../utils/functions'
import { useCreateStageMutation } from '../../redux/stageService/stageApiSlice'
import { setCurrentStageNumber } from '../../redux/stageService/stageSlice'

function NoContent({ table, structure, build, noStage }) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const params = useParams()
	const wildcardValue = params['*']
	const { stageNumber, tournamentParams } = params
	const { tournamentUid } = getParameters(tournamentParams)
	const { participants_count } = useSelector(selectTournament)
	const options = useSelector(selectTournamentOptions)
	const { stage = {} } = options || {}
	const { authorized, guestCode } = useSelector(selectAuth)
	const initialStage = useSelector(selectInitialStage)

	const [createStage] = useCreateStageMutation()
	const [requestIsActive, setRequestIsActive] = useState(false)

	const [popupAlert, setPopupAlert] = useState(false)

	const textStructureEdit = 'Это вкладка редактирования структуры этапа. Добавляйте/удаляйте группы. Изменяйте количество участников в группах. Выбирайте тип розыгрыша – Round Robin, Olympic+ и др. Разделяйте группы по уровням силы'
	const textStructure = 'На этой вкладке редактируйте состав групп. Для начала создайте группы во вкладке автосоздания (иконка Волшебная палочка)'
	const textStructureBuild = 'или во вкладке редактирования структуры этапа (иконка Гаечный ключ)'

	async function handleAddStage() {
		if (!stage?.createStageWithoutParticipants && participants_count === 0) {
			setPopupAlert(true)
		} else {
			setRequestIsActive(true)

			try {
				const response = await createStage({ tournament_uid: tournamentUid }).unwrap()

				dispatch(setCurrentStageNumber(response.order_number))
				setRequestIsActive(false)

				if (stage?.modes?.tabsAreVisible) {
					navigate(`/tournament/${tournamentUid}/structure/1/generation`)
				}
			}
			catch (e) {
				console.log('authomatical creation in stageCreation catch error', e)
				setRequestIsActive(false)
			}
		}
	}

	function goToTab(path) {
		if (wildcardValue === path) {
			return
		}

		navigate(`/tournament/${tournamentUid}/structure/${stageNumber}/` + path)
	}

	function goToStructure() {
		navigate(`/tournament/${tournamentUid}/structure/${stageNumber}`)
	}

	function goToParticipants() {
		navigate(`/tournament/${tournamentUid}/participants`)
	}

	return (
		<div className="no-content">
			{
				table && !noStage &&
				<p className="no-content__title">
					{t(`${table} еще нет`)}
				</p>
			}

			{
				authorized && !guestCode ?
					!requestIsActive ?
						<div className="no-content__wrapper">
							{
								(!initialStage || !initialStage?.levels) && !noStage ?
									<p
										className={`no-content__description ${!structure && !table ? 'no-content__description--margin' : ''}`}
									>
										{
											!stage?.createStageWithoutParticipants ?
												participants_count > 0 ?
													<>
														Нажмите на плюс для создания этапа

														<button className="structure-icon">
															<RoundPlusIcon onClick={handleAddStage} />
														</button>
													</>
													:
													<>
														Добавьте участников

														<button className="participants-icon">
															<ParticipantsIcon onClick={goToParticipants} />
														</button>
													</>
												: structure ?
													<>
														Нажмите на плюс для создания этапа

														<button className="structure-icon">
															<RoundPlusIcon onClick={handleAddStage} />
														</button>
													</>
													:
													<>
														Создайте этап во вкладке "Структура"

														<button className="structure-icon">
															<StructureIcon
																onClick={goToStructure}
															/>
														</button>
													</>
										}
									</p>
									:
									<p
										className={`no-content__description ${!structure && !table ? 'no-content__description--margin' : ''}`}
										style={{
											fontSize: structure ? '15px' : ''
										}}
									>
										{noStage ?
											<span className="no-content__description-nostage">Такого этапа нет</span>
											:
											build && structure ?
												textStructureEdit
												: structure ?
													`${textStructure}${stage?.modes?.build ? ' ' + textStructureBuild : ''}`
													: 'Перейдите в раздел "Структура" для создания групп'
										}

										{!noStage ?
											structure ?
												<button>
													<div className="no-content__structure-icons">
														<MagicStickIcon onClick={() => goToTab('generation')} />
														{stage?.modes?.build && <BuildIcon onClick={() => goToTab('build')} />}
													</div>
												</button>
												:
												<button className="structure-icon" onClick={goToStructure}>
													<StructureIcon />
												</button>
											:
											''
										}
									</p>
							}
						</div>
						:
						<RollingIcon />
					:
					''
			}

			{
				popupAlert &&
				<Popup title="Внимание">
					<div className="nocontent__popup-wrapper">
						{
							<p className="nocontent__popup-description">
								В режиме {options?.name} вы не можете создать этап без игроков
							</p>
						}

						<RoundedButton
							title="Хорошо"
							onClick={() => setPopupAlert(!popupAlert)}
							border
						/>
					</div>
				</Popup>
			}
		</div>
	)
}

export default NoContent