import { TYPE_SINGLES } from '../../../utils/constants'
import { checkEquality } from '../../../utils/functions'

export function dragItems(result, levels) {
	const [sourceLevelIndex, sourceGroupIndex] = result.source.droppableId.split('-')
	const [endLevelIndex, endGroupIndex] = result.destination.droppableId.split('-')

	const movedNode = {
		...levels[sourceLevelIndex]?.groups[sourceGroupIndex]?.nodes[result.source.index],
	}
	const replacedNode = {
		...levels[endLevelIndex]?.groups[endGroupIndex]?.nodes[result.destination.index]
	}

	const updatedMovedNode = {
		...movedNode,
		name: replacedNode.name,
		participant: replacedNode.participant,
		playerUid: replacedNode.playerUid,
		doubleUid: replacedNode.doubleUid,
		dependsOnUid: replacedNode.dependsOnUid,
		rating: replacedNode.rating
	}

	const updatedReplacedNode = {
		...replacedNode,
		name: movedNode.name,
		participant: movedNode.participant,
		playerUid: movedNode.playerUid,
		doubleUid: movedNode.doubleUid,
		dependsOnUid: movedNode.dependsOnUid,
		rating: movedNode.rating
	}

	return {
		sourceLevelIndex,
		endLevelIndex,
		endGroupIndex,
		sourceGroupIndex,
		endIndex: result.destination.index,
		sourceIndex: result.source.index,
		movedNode: updatedMovedNode,
		replacedNode: updatedReplacedNode
	}
}

function checkNodesEquality(levels, immutableLevels, type) {
	// если в текущем состоянии этапа какая-то нода отличается от первоначальной, то возвращаем false
	return !levels?.some((lvl, lvlIndex) => {
		return lvl.groups.some((grp, grpIndex) => {
			const immutableGroup = immutableLevels[lvlIndex]?.groups[grpIndex]

			if (type === TYPE_SINGLES) {
				return grp?.nodes?.some((node, index) => {
					const immutableNode = immutableGroup?.nodes[index]

					return node?.playerUid !== immutableNode?.playerUid || node?.name !== immutableNode?.name
				})
			} else {
				return grp?.nodes?.some((node, index) => {
					const immutableNode = immutableGroup?.nodes[index]

					return node?.doubleUid !== immutableNode?.doubleUid || node?.name !== immutableNode?.name
				})
			}
		})
	})
}

function cleanPlacedAttribute(participantsList) {
	return participantsList.map((item) => {
		return {
			...item,
			placed: false
		}
	})
}

function checkLevelsAndGroups(levels, levelsMemory) {
	const levelsMemoryLength = levelsMemory.length
	const levelsLength = levels.length

	if (levelsMemoryLength !== levelsLength) return true

	const groupMemory = levelsMemory.map(level => {
		return level.groups
	})
	const groupMemoryLength = groupMemory[0].length

	const group = levels.map(level => {
		return level.groups
	})
	const groupLength = group[0].length

	if (groupMemoryLength !== groupLength) {
		return true
	} else {
		let isDifferent = false

		levelsMemory.forEach((groups, groupsIndex) => {
			groups.groups.forEach((group, groupIndex) => {
				if ((group.participants_max !== levels[groupsIndex].groups[groupIndex].participants_max)
					|| (group.type !== levels[groupsIndex].groups[groupIndex].type)) {
					isDifferent = true
				}
			})
		})

		return isDifferent
	}
}

function formNodesForRequest(editedLevels, initialStage, playersList) {
	const editedNodes = []

	editedLevels?.forEach((lvl, lvlIndex) => {
		lvl?.groups?.forEach((grp, grpIndex) => {
			grp?.nodes?.forEach((node, nodeIndex) => {
				const initNode = initialStage?.levels[lvlIndex]?.groups[grpIndex]?.nodes[nodeIndex]

				if (
					(node.playerUid && initNode.player_uid && node.playerUid !== initNode.player_uid)
					|| (node.doubleUid && initNode.double_uid && node.doubleUid !== initNode.double_uid)
					|| (node.name && initNode.name && node.name !== initNode.name)
					|| (node.playerUid && !initNode.player_uid)
					|| (!node.playerUid && initNode.player_uid)
					|| (node.doubleUid && !initNode.double_uid)
					|| (!node.doubleUid && initNode.double_uid)
					|| (node.name && !initNode.name)
					|| (!node.name && initNode.name)
				) {
					editedNodes.push(node)
				}
			})
		})
	})

	const updatedNodes = editedNodes.map(editedNode => {
		const matchingPlayer = playersList.find(player => editedNode.name && player.name === editedNode.name)
		return {
			uid: editedNode.nodeUid,
			depends_on_uid: matchingPlayer?.nodeUid || null
		}
	})

	return { nodes: updatedNodes }
}

export {
	cleanPlacedAttribute,
	checkLevelsAndGroups,
	checkNodesEquality,
	formNodesForRequest
}