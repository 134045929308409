import { useState, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './DoubleSearchForPlayers.scss'
import { addDouble, setUserChangedList, setNoSortedParticipants } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import Input from '../../reusableComponents/Input/Input'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
// import { ReactComponent as CheckMark } from '../../icons/checkmark.svg'
import { NOSORT, STRENGTH } from '../../utils/constants'
import { ReactComponent as SortIcon } from '../../icons/players_sort.svg'
import SortPlayersModal from '../../reusableComponents/SortPlayersModal/SortPlayersModal'
import Loader from '../../reusableComponents/Loader/Loader'
import { selectCommunityPlayers, selectTournament } from '../../redux/selectors'
import { setCommunityPlayers } from '../../redux/playersService/playersSlice'

import { generateRandomUid } from './externalFunctions'

const DoubleSearchForPlayers = ({
	search,
	searchPlayersList,
	participants,
	playersLoading,
	limitIsReached
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {
		players,
		doubles,
		type
	} = useSelector(selectTournament)
	const { initPlayers } = useSelector(selectCommunityPlayers)

	const [firstPlayer, setFirstPlayer] = useState('')
	const [secondPlayer, setSecondPlayer] = useState('')
	const [chosenSearchInput, setChosenSearchInput] = useState('')
	const [chosenPlayers, setChosenPlayers] = useState({})
	const [openSort, setOpenSort] = useState(false)
	const [sortMethod, setSortMethod] = useState('')
	const inputOne = '1'
	const inputTwo = '2'

	const handleInputOfDoublePopup = useCallback((value) => {
		setChosenSearchInput(value)

		if (value !== chosenSearchInput) {
			// сбрасываем фильтр если инпут сменился
			search('', sortMethod)
		}
	}, [search, sortMethod, chosenSearchInput])

	const handleFirstInput = useCallback((value) => {
		if (value === '') {
			setFirstPlayer('')
			setChosenPlayers({
				...chosenPlayers,
				player1: {}
			})
			search('', sortMethod)
		} else {
			setFirstPlayer(value)
			search(value, sortMethod)
		}
	}, [search, chosenPlayers, sortMethod])

	const handleSecondInput = useCallback((value) => {
		if (value === '') {
			setSecondPlayer('')
			setChosenPlayers({
				...chosenPlayers,
				player2: {}
			})
			search('', sortMethod)
		} else {
			setSecondPlayer(value)
			search(value, sortMethod)
		}

		search(value, sortMethod)
	}, [search, chosenPlayers, sortMethod])

	const updateFirstPlayer = useCallback((player) => {
		setFirstPlayer(`${player?.last_name} ${player?.first_name}`)

		setChosenPlayers({
			...chosenPlayers,
			player1: {
				uid: player?.uid,
				last_name: player?.last_name,
				first_name: player?.first_name,
				formedName: player?.formedName,
				strength: player?.strength
			}
		})
	}, [chosenPlayers])

	const updateSecondPlayer = useCallback((player) => {
		setSecondPlayer(`${player?.last_name} ${player?.first_name}`)

		setChosenPlayers({
			...chosenPlayers,
			player2: {
				uid: player?.uid,
				last_name: player?.last_name,
				first_name: player?.first_name,
				formedName: player?.formedName,
				strength: player?.strength
			}
		})
	}, [chosenPlayers])

	const isInvolvedPlayer = useCallback((player) => {
		return participants?.some(({ player1, player2 }) =>
			player1?.uid === player?.uid || player2?.uid === player?.uid
		)
	}, [participants])

	const handlePlayerInPopupList = useCallback((player) => {
		if (chosenPlayers?.player1?.uid === player?.uid || chosenPlayers?.player2?.uid === player?.uid) {
			return
		}

		if (isInvolvedPlayer(player)) {
			return
		}

		if (chosenSearchInput === inputOne || (!firstPlayer && chosenSearchInput !== inputTwo)) {
			updateFirstPlayer(player)
		} else {
			updateSecondPlayer(player)
		}
	}, [updateFirstPlayer, updateSecondPlayer, chosenSearchInput, firstPlayer, chosenPlayers, isInvolvedPlayer])

	function getNewDoubel(double) {
		const savedDOuble = doubles?.find(d =>
			d.player1.uid === double.player1.uid && d.player2.uid === double.player2.uid
		)

		if (savedDOuble) {
			return savedDOuble
		} else {
			return {
				uid: generateRandomUid(),
				player1: double?.player1,
				player2: double?.player2
			}
		}
	}

	const handleSaveButton = useCallback(() => {
		if (limitIsReached || !firstPlayer || !secondPlayer
			|| !chosenPlayers?.player1 || !chosenPlayers?.player2) {
			return
		}

		const addedDouble = getNewDoubel(chosenPlayers)

		dispatch(addDouble({ participant: addedDouble }))
		dispatch(setNoSortedParticipants([...participants, addedDouble])) // если после этого будет сортировка, то откатится к этому списку
		dispatch(setUserChangedList(true))

		setFirstPlayer('')
		setSecondPlayer('')
		setChosenSearchInput('')
		setChosenPlayers({})
	}, [
		dispatch, chosenPlayers, secondPlayer,
		firstPlayer, type, limitIsReached,
		players, doubles, participants
	])

	function sortPlayers({ sortMethod : method }) {
		let sorted = [...searchPlayersList]

		if (method === NOSORT) {
			dispatch(setCommunityPlayers(initPlayers))
		} else if (method === STRENGTH) {
			sorted = sorted.sort((a, b) => {
				return Number(b.strength ?? 0) - Number(a.strength ?? 0)
			})

			dispatch(setCommunityPlayers(sorted))
		} else {
			sorted = sorted.sort((a, b) => {
				const order = { 'male': 1, 'female': 2, null: 3 }
				return order[a.gender] - order[b.gender]
			})

			dispatch(setCommunityPlayers(sorted))
		}

		setOpenSort(false)
		setSortMethod(method)
		dispatch(setUserChangedList(true))
	}

	if ((playersLoading === 'pending') || (playersLoading === 'uninitialized')) {
		return <Loader />
	}

	return (
		<>
			<div className="double-search-players">
				<div className="double-search-players__input--first">
					<Input
						type="search"
						autoComplete="off"
						placeholder={t('Add player')}
						value={firstPlayer}
						borderColor={chosenSearchInput === inputOne && '#367BCF'}
						onChange={(e) => handleFirstInput(e.target.value)}
						onClick={() => handleInputOfDoublePopup(inputOne)}
					/>
				</div>

				<div className="double-search-players__input--second">
					<Input
						type="search"
						autoComplete="off"
						placeholder={t('Add player')}
						value={secondPlayer}
						borderColor={chosenSearchInput === inputTwo && '#367BCF'}
						onChange={(e) => handleSecondInput(e.target.value)}
						onClick={() => handleInputOfDoublePopup(inputTwo)}
					/>
				</div>

				<div className="double-search-players__buttons">
					<RoundedButton
						title={t('Save double')}
						onClick={() => handleSaveButton()}
						disabled={limitIsReached}
					/>

					<button className="double-search-players__button-sort" onClick={() => setOpenSort(true)}>
						<SortIcon />
					</button>
				</div>
			</div>

			{
				searchPlayersList?.length === 0 &&
				<span className="double-search-players__line"></span>
			}

			{
				searchPlayersList?.map((el, index) =>
					<div
						key={index}
						className="double-search-players__list-item"
						style={{
							backgroundColor:
								chosenPlayers?.player1?.uid === el?.uid || chosenPlayers?.player2?.uid === el?.uid ||
									isInvolvedPlayer(el) ? 'var(--palette-live-blue-3)'
									:
									limitIsReached ? 'var(--palette-live-grey-8)'
										: ''
						}}
						onClick={() => handlePlayerInPopupList(el)}
					>
						<p className="double-search-players__player-name">
							{el?.last_name} {el?.first_name}
						</p>

						<p className="double-search-players__player-strength">
							{el?.strength || ''}
						</p>

						{/* {
							isInvolvedPlayer(el) &&
							<CheckMark />
						} */}
					</div>
				)
			}

			{openSort &&
				<SortPlayersModal
					sortMethod={sortMethod}
					participants={searchPlayersList}
					submit={sortPlayers}
					gender
				/>
			}
		</>
	)
}

export default memo(DoubleSearchForPlayers)
