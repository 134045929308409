import { checkEquality } from '../../../utils/functions'

function checkLevelsAndGroupsChanges(levels, levelsMemory) {
	const levelsMemoryLength = levelsMemory?.length
	const levelsLength = levels?.length

	const changes = {
		noChages: true,
		groupChanged: false
	}

	if (levelsMemoryLength !== levelsLength) {
		// если количество уровней изменилось

		changes.noChages = false
		return changes
	}

	const groupMemory = levelsMemory?.map(level => {
		return level?.groups.length
	})

	const group = levels?.map(level => {
		return level.groups.length
	})

	if (!checkEquality(groupMemory, group)) {
		// если количество групп изменилось

		changes.noChages = false
		return changes
	}

	// если параметры групп изменились
	levelsMemory?.some((groups, groupsIndex) => {
		return groups.groups.some((group, groupIndex) => {
			if (
				(group.participants_max !== levels[groupsIndex].groups[groupIndex].participants_max)
				|| (group.type !== levels[groupsIndex].groups[groupIndex].type)
				|| (group.uid !== levels[groupsIndex].groups[groupIndex].uid)
			) {
				changes.noChages = false
				changes.groupChanged = true

				return true
			}

			return false
		})
	})

	return changes
}

function getGroupsAndNodesQuantity(levels) {
	let groups = 0
	let nodes = 0

	levels.forEach(lvl => {
		groups =  groups + lvl?.groups?.length || 0
		
		lvl?.groups?.forEach(grp => {
			nodes = nodes + grp?.nodes?.length || 0
		})
	})

	return { groups, nodes }
}

export {
	checkLevelsAndGroupsChanges,
	getGroupsAndNodesQuantity
}