import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setInitialStage } from '../redux/stageService/stageSlice'

function useProcessStageData(stageData) {
	const dispatch = useDispatch()

	useEffect(() => {
		if (Object.keys(stageData).length !== 0) {
			dispatch(setInitialStage(stageData))
		}
	}, [stageData])

	return
}

export { useProcessStageData }