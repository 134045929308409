import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useKeenSlider } from 'keen-slider/react'

import './StagesMenu.scss'
import { ReactComponent as PenIcon } from '../../icons/pen_stage.svg'
import { ReactComponent as MagicStickIcon } from '../../icons/magic_stick.svg'
import { ReactComponent as RollingIcon } from '../../icons/rolling.svg'
import {
	selectAuth,
	selectCurrentStageNumber,
	selectInitialStage,
	selectTournament,
	selectTournamentOptions
} from '../../redux/selectors'
import { getParameters } from '../../utils/functions'
import { setCurrentStageNumber, setInitialStage, setProcessedLevelsForBuildMode, setProcessedStage } from '../../redux/stageService/stageSlice'
import { useCreateStageMutation } from '../../redux/stageService/stageApiSlice'
import { ReactComponent as BuildIcon } from '../../icons/build__stage.svg'
import Popup from '../Popup/Popup'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import { getExtraPath, isNumeric } from '../../utils/functions2'
import { BUILD, GENERATION, STRUCTURE } from '../../utils/constants'

function StagesMenu({ structure }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const params = useParams()
	const wildcardValue = params['*']
	const { authorized, guestCode } = useSelector(selectAuth)
	const [generateStage] = useCreateStageMutation()
	const { tournamentParams, stageNumber } = useParams()
	const stageNumberState = useSelector(selectCurrentStageNumber)
	const { tournamentUid } = getParameters(tournamentParams)
	const { stages_count, tier, participants_count } = useSelector(selectTournament)
	const options = useSelector(selectTournamentOptions)
	const { stage: stageOptions = {}, settings = {} } = options || {}
	const initialStage = useSelector(selectInitialStage)

	const [popupAlert, setPopupAlert] = useState(false)
	const [createStageLoading, setCreateStageLoading] = useState(false)

	const iconContainerWidthForThreeTabs = 61
	const iconContainerWidthForTwoTabs = 72
	const iconWidth = 39
	const minStagesCountToSlide = 3

	const stages = useMemo(() => {
		return Array.from({ length: stages_count }, (_, index) => index + 1)
	}, [stages_count])

	const [sliderRef, slider] = useKeenSlider({
		slides: { perView: 'auto' },
		initial: stageNumberState - 1,
		renderMode: 'performance'
	})

	useEffect(() => {
		if (!isNumeric(stageNumber)) {
			navigate('/*')
		}
	}, [stageNumber, navigate])

	useEffect(() => {
		if (slider?.current && stages_count > 0) {
			setTimeout(() => {
				const indexDestination = slider?.current?.size && slider?.current?.size < 229 ? stageNumberState - 1
					: stageNumberState - 2
				slider?.current?.moveToIdx(indexDestination, false, { duration: 900 })
			}, 900)

			setTimeout(() => {
				slider?.current?.update()
			}, 600)
		}
	}, [slider, stageNumberState, stages_count])

	const handleStageTab = useCallback((number) => {
		if (Number(number) === Number(stageNumber)) {
			return
		}

		dispatch(setCurrentStageNumber(number))
		dispatch(setProcessedStage({}))
		dispatch(setInitialStage({}))
		dispatch(setProcessedLevelsForBuildMode([]))

		const fullPath = getExtraPath(location, number)
		navigate(fullPath)
	}, [navigate, dispatch, location])

	const handleAddStage = useCallback(async () => {
		if (!stageOptions?.createStageWithoutParticipants && participants_count === 0) {
			setPopupAlert(true)
		} else {
			try {
				setCreateStageLoading(true)
				const response = await generateStage({ tournament_uid: tournamentUid }).unwrap()

				const newStageNumber = response.order_number

				if (stageOptions?.modes?.tabsAreVisible) {
					handleStick(newStageNumber)
				} else {
					const tournamentTab = location?.pathname.split('/')[3]
					navigate(`/tournament/${tournamentUid}/${tournamentTab}/${newStageNumber}`)
				}

				setTimeout(() => {
					dispatch(setCurrentStageNumber(newStageNumber))
				}, 500)

				setCreateStageLoading(false)
			}
			catch (e) {
				setCreateStageLoading(false)
				console.log('authomatical creation in stageCreation catch error', e)
			}
		}
	}, [
		tournamentUid, generateStage, navigate, location,
		participants_count, tier, dispatch, wildcardValue, stageOptions
	])

	function isActiveStage(number) {
		const tab = Number(stageNumber)

		return tab === number
	}

	function handleStick(num) {
		const numberOfStage = num ? num : stageNumberState > 0 ? stageNumberState : 1
		navigate(`/tournament/${tournamentUid}/${STRUCTURE}/${numberOfStage}/` + GENERATION)
	}

	function handleBuild() {
		navigate(`/tournament/${tournamentUid}/${STRUCTURE}/${stageNumberState}/` + BUILD)
	}

	function handlePen() {
		navigate(`/tournament/${tournamentUid}/${STRUCTURE}/${stageNumberState}`)
	}

	function widthByModes(element) {
		const { fill, build, auto } = stageOptions?.modes || {}
		const tabsCount = Number(fill) + Number(build) + Number(auto)

		if (element === 'container') {
			const containerSectionSize = tabsCount > 2 ? iconContainerWidthForThreeTabs : iconContainerWidthForTwoTabs
			return `${tabsCount * containerSectionSize}px`
		} else {
			return `${tabsCount * iconWidth}px`
		}
	}

	const addStageButtonIsShown = useMemo(() => {
		if (stages_count < settings?.maxStages && authorized && !guestCode && Number(stageNumber) <= stages_count && Number(stageNumber) > 0) {
			if (stageOptions?.modes?.tabsAreVisible && structure) {
				return true
			} else if (
				!stageOptions?.modes?.tabsAreVisible && initialStage?.levels) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}, [
		stages_count, settings?.maxStages, authorized, guestCode,
		structure, stageOptions?.modes?.tabsAreVisible, initialStage,
		stageNumber
	])

	return (
		<div className="stages-menu">
			{
				!structure || !stageOptions?.modes?.tabsAreVisible ?
					<div className="stages-menu__wrapper">
						{
							stages.length > 0 && stages.map((s, index) => (
								<button
									type="button"
									onClick={() => handleStageTab(index + 1)}
									className={
										!isActiveStage(index + 1) ?
											'stages-menu__button--inactive'
											:
											'stages-menu__button--active'
									}
									key={index}
								>
									{index + 1}
								</button>
							))
						}
					</div>
					:
					<div
						className="stages-menu__wrapper keen-slider"
						ref={sliderRef}
						style={{
							margin: settings?.maxStages < minStagesCountToSlide ? '0' : ''
						}}
					>
						{
							stages.length > 0 && stages.map((s, index) => (
								<div
									key={index}
									className={'keen-slider__slide'}
								>
									<div
										className={
											`stages-menu__item${isActiveStage(index + 1) ? ' active' : ''}`
										}
										style={{
											width: isActiveStage(index + 1) ? widthByModes('container') : ''
										}}
									>
										<button
											type="button"
											onClick={() => handleStageTab(index + 1)}
											className="stages-menu__item-button"
										>
											{index + 1}
										</button>

										<div
											className="stages-menu__item-icons"
											style={{
												width: isActiveStage(index + 1) ? widthByModes('icon') : ''
											}}
										>
											{stageOptions?.modes?.fill &&
												<PenIcon
													onClick={handlePen}
													className={
														wildcardValue === '' ?
															'stages-menu__item-icon--active'
															:
															'stages-menu__item-icon'
													}
												/>
											}

											{
												stageOptions?.modes?.build &&
												<BuildIcon
													onClick={handleBuild}
													className={
														wildcardValue === 'build' ?
															'stages-menu__item-icon--active'
															:
															'stages-menu__item-icon'
													}
												/>
											}

											{stageOptions?.modes?.auto &&
												<MagicStickIcon
													onClick={() => handleStick(null)}
													className={
														wildcardValue === 'generation' ?
															'stages-menu__item-icon--active'
															:
															'stages-menu__item-icon'
													}
												/>
											}
										</div>
									</div>
								</div>
							))
						}
					</div>
			}

			{
				addStageButtonIsShown &&
				<div className="stages-menu__block">
					{stages_count > 1 && settings?.maxStages >= minStagesCountToSlide &&
						<div className="stages-menu__block-line"></div>
					}

					{
						(stageOptions.createStageWithoutParticipants) || (participants_count > 0 && !stageOptions.createStageWithoutParticipants) ?
							<button
								type="button"
								onClick={() => handleAddStage()}
								className="stages-menu__block-button-add"
							>
								{
									createStageLoading ?
										<RollingIcon
											style={{
												stroke: 'var(--palette-live-grey-7)'
											}}
										/>
										:
										'+'
								}
							</button>
							:
							null
					}
				</div>
			}

			{
				popupAlert &&
				<Popup title="Внимание">
					<div className="stages-menu__popup-wrapper">
						{
							<p className="nocontent__popup-description">
								В режиме {options?.name} вы не можете создать этап без игроков
							</p>
						}

						<RoundedButton
							title="Хорошо"
							onClick={() => setPopupAlert(!popupAlert)}
							border
						/>
					</div>
				</Popup>
			}
		</div >
	)
}

export default memo(StagesMenu)