import { memo } from 'react'

import './IconButton.scss'
import { ReactComponent as RoundPlusIcon } from '../../icons/round_plus.svg'

function IconButton({ title, onClick }) {
	return (
		<button
			className="icon-button"
		>
			<RoundPlusIcon className="round-plus__icon" onClick={onClick}/>

			<span className="icon-button__title" onClick={onClick}>
				{ title }
			</span>
		</button>
	)
}

export default memo(IconButton)